import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import useLocalisedMoment from '../../../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import getDayStyles from '../../../../helpers/get-day-styles';

const DayContents = ({
  day,
  daysAvailability,
  availableSelectionRange,
  isLoading,
  printPrice,
  numRooms,
  selectedRange,
  restrictedDates,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const date = daysAvailability.find(
    ({ date }) => date === moment(day).format('YYYY-MM-DD')
  );

  const { className, tooltipContent } = useMemo(
    () =>
      getDayStyles(
        date,
        moment,
        t,
        availableSelectionRange,
        daysAvailability,
        selectedRange,
        restrictedDates
      ),
    [
      date,
      moment,
      t,
      availableSelectionRange,
      daysAvailability,
      selectedRange,
      restrictedDates,
    ]
  );

  const isEllipsisActive = (e) => {
    return e?.offsetWidth < e?.scrollWidth;
  };

  if (!isLoading && daysAvailability.length > 0) {
    const date = daysAvailability.find(
      ({ date }) => date === moment(day).format('YYYY-MM-DD')
    );

    return (
      <div
        id={`day-${date?.date}`}
        data-testid={`day-${date?.date}`}
        className={className}
      >
        <div className="clipPathDiv"></div>
        <div className="d-flex flex-column align-items-center">
          {day.format('DD')}
          {date?.minimumPrice?.value && numRooms === 1 && (
            <>
              <span className="minimum-price" id={`price-${date?.date}`}>
                {
                  printPrice(
                    {
                      baseCurrency: date.minimumPrice.currencyCode,
                      value: date.minimumPrice.value,
                      valueExcludingTaxesAndFees:
                        date.minimumPrice.valueExcludingTaxesAndFees,
                    },
                    true
                  ).price
                }
              </span>
              {!tooltipContent &&
                isEllipsisActive(
                  document.getElementById(`price-${date?.date}`)
                ) && (
                  <UncontrolledTooltip
                    placement="top"
                    target={`day-${date?.date}`}
                    style={{ textAlign: 'left' }}
                  >
                    {
                      printPrice(
                        {
                          baseCurrency: date.minimumPrice.currencyCode,
                          value: date.minimumPrice.value,
                          valueExcludingTaxesAndFees:
                            date.minimumPrice.valueExcludingTaxesAndFees,
                        },
                        true
                      ).price
                    }
                  </UncontrolledTooltip>
                )}
            </>
          )}
          {tooltipContent && date?.date && (
            <UncontrolledTooltip
              placement="top"
              target={`day-${date?.date}`}
              style={{ textAlign: 'left' }}
            >
              {tooltipContent}
            </UncontrolledTooltip>
          )}
        </div>
      </div>
    );
  } else {
    return <div>{day.format('DD')}</div>;
  }
};

DayContents.propTypes = {
  day: PropTypes.object,
  daysAvailability: PropTypes.array,
  availableSelectionRange: PropTypes.array,
  restrictedDates: PropTypes.array,
  selectedRange: PropTypes.array,
  isLoading: PropTypes.bool,
  printPrice: PropTypes.func,
  numRooms: PropTypes.number,
};

export default DayContents;
