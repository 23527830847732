import React from 'react';
import Skeleton from 'react-loading-skeleton';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './SelectHotelLoader.module.css';

const SelectHotelLoader = () => {
  const { t } = useTranslate();

  return (
    <div className={styles.SelectHotelLoader__container}>
      <div className={styles.SelectHotelLoader__title}>
        {t('Belmond Properties')}
      </div>
      <div className={styles.SelectHotelLoader__grid}>
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={`SelectHotelLoader-${index.toString()}`}
            className={styles.SelectHotelLoader__item}
          >
            <Skeleton
              height={180}
              className={styles.SelectHotelLoader__image_skeleton}
            />
            <div>
              <Skeleton
                height={80}
                className={styles.SelectHotelLoader__text_skeleton}
              />
              <Skeleton
                height={60}
                className={styles.SelectHotelLoader__text_skeleton}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectHotelLoader;
