const getIsDayBlocked = (
  day,
  isLoading,
  daysAvailability,
  focus,
  availableSelectionRange,
  moment
) => {
  if (isLoading) return false;
  if (!daysAvailability || !availableSelectionRange) return true;

  const date = daysAvailability.find(
    ({ date }) => date === moment(day).format('YYYY-MM-DD')
  );

  const isAvailableForStart = date?.isCheckIn;
  const isDayBlocked = !isAvailableForStart && focus === 'startDate';

  if (availableSelectionRange.length > 0) {
    const isAvailable = availableSelectionRange.includes(
      moment(day).format('YYYY-MM-DD')
    );

    return !isAvailable;
  } else {
    return isDayBlocked;
  }
};

export default getIsDayBlocked;
