import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import BookingsCart from '../../components/BookingsCart/BookingsCart';
import BookingsCartMobileBar from '../../components/BookingsCartMobileBar/BookingsCartMobileBar';
import BookingsConfirmationMobileBar from '../../components/BookingsConfirmationMobileBar/BookingsConfirmationMobileBar';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import {
  PAYMENT_STATUS_DONE,
  PAYMENT_STATUS_EXPIRED,
  PAYMENT_STATUS_INIT_FAILING,
  PAYMENT_STATUS_JUST_DONE,
  PAYMENT_STATUS_PENDING,
} from '../../constants';
import { useScreenReady } from '../../context/use-screen-ready';
import {
  gtmBookingStepProgress,
  gtmPageView,
  gtmPurchase,
} from '../../gtm/events';
import { mapBookings } from '../../helpers/mapBookings/mapBookings';
import useAxios from '../../hooks/useAxios/useAxios';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import useMessage from '../../hooks/useMessage/useMessage';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import {
  addBooking,
  bookingStatuses,
  bookingsCartStatuses,
  resetBookingsState,
  setCurrentBooking,
} from '../../redux/slices/bookingsSlice/bookingsSlice';
import {
  resetGuestFormState,
  setGuestFormValuesFromObjectsArray,
} from '../../redux/slices/guestFormSlice/guestFormSlice';
import { resetPaymentState } from '../../redux/slices/paymentSlice/paymentSlice';
import Payment from '../GuestForm/components/Payment/Payment';
import styles from './BookingsConfirmation.module.css';
import BookingConfirmationDetails from './components/BookingConfirmationDetails/BookingConfirmationDetails';
import BookingConfirmationMessage from './components/BookingConfirmationMessage/BookingConfirmationMessage';
import BookingsConfirmationActionButtons from './components/BookingsConfirmationActionButtons/BookingsConfirmationActionButtons';
import FailedAddonsActionsMessage from './components/FailedAddonsActionsMessage/FailedAddonsActionsMessage';
import FailedBookingsMessage from './components/FailedBookingsMessage/FailedBookingsMessage';
import PaymentMessage from './components/PaymentMessage.js/PaymentMessage';
import TotalPriceBox from './components/TotalPriceBox/TotalPriceBox';

const { CONFIRMED, ORDERED, CANCELLED } = bookingStatuses;
const { EDIT_ADDONS, STAND_BY } = bookingsCartStatuses;

const THREE_DS_AUTH = 'auth';
const THREE_DS_SUBMIT_CODE = 'submitCode';

const BookingsConfirmation = () => {
  const {
    t,
    constants: { errorMessage },
  } = useTranslate();
  const moment = useLocalisedMoment();
  const { setIsScreenReady } = useScreenReady();
  const axios = useAxios();
  const [Message, showMessage, closeMessage] = useMessage();
  const dispatch = useDispatch();
  const paymentRef = useRef();
  const bookingsConfirmationViewRef = useRef(null);
  const bookingsCartMobileBarRef = useRef();
  const bookingsConfirmationMobileBarRef = useRef();
  const firstBookingsFetch = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLargeDesktop } = useScreenDetector();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const productCodeParam = searchParams.get('productCode');
  const bookingIdParam = searchParams.get('bookingId');
  const encryptedEmailParam = searchParams.get('email');
  const paymentIdParam = searchParams.get('pId');

  const [submittingPayment, setSubmittingPayment] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [actionDone, setActionDone] = useState(false);
  const [fetchingBookings, setFetchingBookings] = useState(true);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [cancelBookingsError, setCancelBookingsError] = useState('');
  const [cancelBookingsLoading, setCancelBookingsLoading] = useState(false);
  const [addonsLoading, setAddonsLoading] = useState(false);
  const [addonsError, setAddonsError] = useState('');
  const [pendingAddonsActions, setPendingAddonsActions] = useState({
    toAdd: [],
    toRemove: [],
  });
  const [addonsChangesConfirmed, setAddonsChangesConfirmed] = useState(false);
  const [lastCancelledBooking, setLastCancelledBooking] = useState(null);
  const [failingAddonsActions, setFailingAddonsActions] = useState(null);
  const [gtmCalled, setGtmCalled] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bookingId, setBookingId] = useState(bookingIdParam);
  const [encryptedEmail, setEncryptedEmail] = useState(encryptedEmailParam);
  const [threeDsPaymentStatus, setThreeDsPaymentStatus] = useState(null);

  const accessToken = useSelector((state) => state.user.accessToken);
  const hotels = useSelector((state) => state.belmond.hotels);
  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const editingAddons =
    pendingAddonsActions.toAdd.length > 0 ||
    pendingAddonsActions.toRemove.length > 0;

  const someBookingConfirmed = bookings.some(
    (booking) => booking.status === CONFIRMED
  );
  const someBookingOrdered = bookings.some(
    (booking) => booking.status === ORDERED
  );

  const actionableBookings = bookings.filter((booking) => {
    const daysToCancel = booking.roomRate.cancelTimeIn;
    const { cancellationPolicy } = booking.roomRate;
    const startDate = moment(booking.startDate);
    return (
      cancellationPolicy &&
      daysToCancel &&
      [CONFIRMED, ORDERED].includes(booking.status) &&
      startDate.diff(moment(), 'days') > daysToCancel
    );
  });

  const hasSomeBookings = someBookingConfirmed || someBookingOrdered;
  const displayBookingActions = !editingAddons && !fetchingBookings;

  // we need to use useMemo in order to avoid infinite rerendering of <Payment/>
  const paymentMetadata = useMemo(
    () => ({
      paymentId: paymentIdParam,
    }),
    [paymentIdParam]
  );

  const fetchBookings = useCallback(
    async (fetchingAfterSuccessPayment) => {
      closeMessage();

      setFetchingBookings(true);

      const params = {
        productCode: productCodeParam,
        bookingId,
        email: encryptedEmail,
        itinerary: 1,
      };
      try {
        window.scrollTo({ top: 0, behavior: 'instant' });
        const res = await axios.get('/find-reservation', {
          params,
          ...(!encryptedEmail && accessToken
            ? { headers: { Authorization: `Bearer ${accessToken}` } }
            : {}),
        });

        if (!encryptedEmail) {
          const params = new URLSearchParams(searchParams);
          params.append(
            'email',
            res.data.find(
              (b) => b.id === bookingId && b.property.code === productCodeParam
            ).encryptedEmail
          );
          setSearchParams(params);
        }

        const mappedBookings = mapBookings(
          res.data.map((booking) => ({
            ...booking,
            booking: {
              ...booking.booking,
              status: fetchingAfterSuccessPayment
                ? CONFIRMED
                : booking.booking.status,
            },
          }))
        );
        setBookings(mappedBookings);
      } catch (e) {
        if (fetchingAfterSuccessPayment && e.response?.status === 401) {
          // it means that the payment was successful but the user is not allowed to see booking details
          setPaymentStatus(PAYMENT_STATUS_JUST_DONE);
          setActionDone(false);
        } else {
          showMessage(
            `${t('We apologize.')} ${t(
              'We cannot locate your reservation.'
            )} ${t('Please check your information and try again.')}`,
            'danger'
          );
        }
      }
      setFetchingBookings(false);
      setFailingAddonsActions(null);
    },
    [
      closeMessage,
      productCodeParam,
      bookingId,
      encryptedEmail,
      accessToken,
      searchParams,
      setSearchParams,
      showMessage,
      t,
      axios,
    ]
  );

  const editPendingAddonsActions = useCallback(
    (action, productCode, bookingId, addon) => {
      setCurrentBookingId(bookingId);
      const { addons: currentAddons } = bookings.find(
        (booking) =>
          booking.id === bookingId && booking.productCode === productCode
      );
      const otherAction = action === 'toRemove' ? 'toAdd' : 'toRemove';
      const addonInTheOtherActionsList = !!pendingAddonsActions[
        otherAction
      ].find(
        (a) =>
          a.bookingId === bookingId &&
          a.productCode === productCode &&
          a.addon.code === addon.code
      );
      const addonAlreadyInBooking = currentAddons.find(
        (currentAddon) => currentAddon.code === addon.code
      );

      if (addonInTheOtherActionsList) {
        setPendingAddonsActions((prevState) => ({
          ...prevState,
          [otherAction]: prevState[otherAction].filter(
            (a) =>
              a.bookingId !== bookingId ||
              a.productCode !== productCode ||
              a.addon.code !== addon.code
          ),
        }));
      }

      if (
        (action === 'toAdd' && !addonAlreadyInBooking) ||
        (action === 'toRemove' && addonAlreadyInBooking)
      ) {
        setPendingAddonsActions((prevState) => ({
          ...prevState,
          [action]: [...prevState[action], { productCode, bookingId, addon }],
        }));
      }

      if (addonsChangesConfirmed) {
        setAddonsChangesConfirmed(false);
      }

      if (!isLargeDesktop) {
        bookingsCartMobileBarRef.current.openModal();
      }
      setAddonsError('');
    },
    [bookings, isLargeDesktop, pendingAddonsActions, addonsChangesConfirmed]
  );

  const handleAddAddon = useCallback(
    (productCode, bookingId, addon) => {
      editPendingAddonsActions('toAdd', productCode, bookingId, addon);
    },
    [editPendingAddonsActions]
  );

  const handleRemoveAddon = useCallback(
    (productCode, bookingId, addon) => {
      editPendingAddonsActions('toRemove', productCode, bookingId, addon);
    },
    [editPendingAddonsActions]
  );

  const handleBookingsCartDiscardChangesAddons = () => {
    if (!isLargeDesktop) {
      bookingsCartMobileBarRef.current.closeModal();
    }
    setPendingAddonsActions({ toAdd: [], toRemove: [] });
    setAddonsError('');
  };

  const handleBookingsCartConfirmChangesAddons = async () => {
    setAddonsLoading(true);
    try {
      const body = {
        toAdd: pendingAddonsActions.toAdd.map(
          ({ productCode, bookingId, addon }) => {
            const booking = bookings.find(
              (booking) =>
                booking.id === bookingId && booking.productCode === productCode
            );
            return {
              productCode,
              bookingId,
              encryptedEmail: bookings.find(
                (booking) =>
                  booking.id === bookingId &&
                  booking.productCode === productCode
              ).encryptedEmail,
              addonCode: addon.code,
              numAdults: booking.numAdults,
              startDate: booking.startDate,
              endDate: booking.endDate,
            };
          }
        ),
        toRemove: pendingAddonsActions.toRemove.map(
          ({ productCode, bookingId, addon }) => ({
            productCode,
            bookingId,
            encryptedEmail: bookings.find(
              (booking) =>
                booking.id === bookingId && booking.productCode === productCode
            ).encryptedEmail,
            addonCode: addon.code,
          })
        ),
      };
      const res = await axios.put('/booking-addons', body);

      if (!isLargeDesktop) {
        bookingsCartMobileBarRef.current.closeModal();
      }
      setPendingAddonsActions({ toAdd: [], toRemove: [] });
      setAddonsError('');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      await fetchBookings();
      // we do it after fetching since fetching is setting failing addons actions as null
      if (res.data?.result) {
        setFailingAddonsActions(res.data.result);
      } else {
        setFailingAddonsActions(null);
      }
      setAddonsChangesConfirmed(true);
      setActionDone(true);
    } catch (e) {
      setAddonsError(errorMessage);
    }
    setAddonsLoading(false);
  };

  const handleEditBooking = async (booking) => {
    dispatch(resetGuestFormState());
    dispatch(resetBookingsState());
    dispatch(resetPaymentState());
    dispatch(addBooking(booking));
    dispatch(setCurrentBooking(booking.id));
    dispatch(
      setGuestFormValuesFromObjectsArray(
        [booking].map(
          ({ productCode, id, guest, secondaryGuests, isMarketingOptin }) => ({
            productCode,
            id,
            guest,
            secondaryGuests,
            isMarketingOptin,
          })
        )
      )
    );
    navigate(`/edit-room?productCode=${booking.productCode}`);
  };

  const handleCancelBooking = async (booking) => {
    setCancelBookingsError('');
    setCancelBookingsLoading(true);
    try {
      await axios.post('/cancel-booking', {
        bookingId: booking.id,
        productCode: booking.productCode,
        encryptedEmail: booking.encryptedEmail,
      });
      setAddonsChangesConfirmed(false);
      setLastCancelledBooking(booking);
      if (!isLargeDesktop) {
        bookingsConfirmationMobileBarRef.current.closeModal();
      }
      await fetchBookings();
      setActionDone(true);
    } catch (e) {
      setCancelBookingsError(errorMessage);
    }
    setCancelBookingsLoading(false);
  };

  const handlePaymentButtonClick = () => {
    const isPaymentFormValid = paymentRef.current?.submit();
    if (isPaymentFormValid) {
      setSubmittingPayment(true);
    }
  };

  const handlePaymentSuccess = useCallback(async () => {
    setActionDone(true);
    setPaymentStatus(PAYMENT_STATUS_DONE);
    await fetchBookings(true);
    setSubmittingPayment(false);
  }, [fetchBookings]);

  const handlePaymentFailure = useCallback(() => {
    setThreeDsPaymentStatus(null);
    setSubmittingPayment(false);
  }, []);

  const handlePayment3dsAuth = useCallback(() => {
    setSubmittingPayment(false);
    setThreeDsPaymentStatus(THREE_DS_AUTH);
  }, []);

  const handlePaymentSubmit3dsCode = useCallback(() => {
    setThreeDsPaymentStatus(THREE_DS_SUBMIT_CODE);
  }, []);

  useEffect(() => {
    const initFn = async () => {
      if (paymentIdParam) {
        try {
          const res = await axios.get('/payment', {
            params: { pid: paymentIdParam },
            headers: accessToken
              ? { Authorization: `Bearer ${accessToken}` }
              : {},
          });
          setBookingId(res.data.bookingId);
          if (res.data.encryptedEmail) {
            setEncryptedEmail(res.data.encryptedEmail);
          } else {
            setFetchingBookings(false);
          }
          if (res.data.done) {
            setPaymentStatus(PAYMENT_STATUS_DONE);
          } else if (res.data.expired) {
            setPaymentStatus(PAYMENT_STATUS_EXPIRED);
          } else {
            setPaymentStatus(PAYMENT_STATUS_PENDING);
          }
        } catch (e) {
          setPaymentStatus(PAYMENT_STATUS_INIT_FAILING);
        }
      }
    };
    initFn();
  }, [accessToken, paymentIdParam, axios]);

  useEffect(() => {
    if (
      !bookingId ||
      (paymentIdParam && !encryptedEmail) ||
      firstBookingsFetch.current
    ) {
      return;
    }
    firstBookingsFetch.current = true;
    fetchBookings();
    if (locationState?.checkoutReferral) {
      setActionDone(true);
    }
  }, [
    fetchBookings,
    locationState?.checkoutReferral,
    productCodeParam,
    bookingId,
    encryptedEmail,
    accessToken,
    paymentIdParam,
  ]);

  useEffect(() => {
    if (hotels && !gtmCalled) {
      const hotel = hotels[productCodeParam];
      gtmPageView(hotel);
      gtmBookingStepProgress('Confirmation');

      const gtmPurchaseEventParams = localStorage.getItem(
        'gtmPurchaseEventParams'
      );

      if (gtmPurchaseEventParams) {
        gtmPurchase(JSON.parse(gtmPurchaseEventParams));
        localStorage.removeItem('gtmPurchaseEventParams');
      }

      setGtmCalled(true);
      setIsScreenReady(true);
    }
  }, [gtmCalled, hotels, productCodeParam, setIsScreenReady]);

  useEffect(() => {
    document.title = t('Confirmation');
  }, [t]);

  return (
    <>
      <div
        style={{ display: !isLargeDesktop && editingAddons ? 'flex' : 'none' }}
      >
        <BookingsCartMobileBar
          status={editingAddons ? EDIT_ADDONS : STAND_BY}
          bookings={bookings}
          currentBookingId={currentBookingId}
          pendingAddonsActions={pendingAddonsActions}
          addonsLoading={addonsLoading}
          error={addonsError}
          onRemoveAddon={handleRemoveAddon}
          onConfirmChangesAddons={handleBookingsCartConfirmChangesAddons}
          onDiscardChangesAddons={handleBookingsCartDiscardChangesAddons}
          ref={bookingsCartMobileBarRef}
        />
      </div>
      {(someBookingConfirmed || someBookingOrdered) &&
        !isLargeDesktop &&
        !editingAddons && (
          <BookingsConfirmationMobileBar
            bookings={bookings}
            bookingsConfirmationViewRef={bookingsConfirmationViewRef}
            cancelBookingsError={cancelBookingsError}
            cancelBookingsLoading={cancelBookingsLoading}
            onCancelBooking={handleCancelBooking}
            onEditBooking={handleEditBooking}
            ref={bookingsConfirmationMobileBarRef}
            actionableBookings={actionableBookings}
          />
        )}
      <div ref={bookingsConfirmationViewRef}>
        <div className="container-xxl py-4">
          <Row className="gx-4">
            <Message />
            <>
              <Col xl="8">
                <div>
                  {!!locationState?.failedBookings?.length && (
                    <FailedBookingsMessage
                      bookings={locationState.failedBookings}
                    />
                  )}

                  {!!failingAddonsActions && (
                    <FailedAddonsActionsMessage
                      failingAddonsActions={failingAddonsActions}
                    />
                  )}

                  {!actionDone && (
                    <PaymentMessage
                      paymentStatus={paymentStatus}
                      bookingShown={!!encryptedEmail}
                      bookingId={bookingId}
                      productCode={productCodeParam}
                    />
                  )}

                  {actionDone && (
                    <BookingConfirmationMessage
                      loading={fetchingBookings}
                      bookings={bookings}
                      currentBookingId={bookingId}
                      addonsChangesConfirmed={addonsChangesConfirmed}
                      pendingPaymentEmail={!paymentIdParam}
                      lastCancelledBooking={lastCancelledBooking}
                    />
                  )}

                  {paymentStatus === PAYMENT_STATUS_PENDING && (
                    <div className="mb-3">
                      <h2>{t('Pre-order Booking')}</h2>
                      <Payment
                        ref={paymentRef}
                        onSuccess={handlePaymentSuccess}
                        onFailure={handlePaymentFailure}
                        on3dsAuth={handlePayment3dsAuth}
                        onSubmit3dsCode={handlePaymentSubmit3dsCode}
                        productCode={productCodeParam}
                        metadata={paymentMetadata}
                        countryCode={
                          countryCode || bookings[0]?.guest.address.countryCode
                        }
                      />
                      <div>
                        {!threeDsPaymentStatus && (
                          <Col sm="3">
                            <LoadingButton
                              ariaLabel="Confirm My Booking "
                              loading={submittingPayment}
                              onClick={handlePaymentButtonClick}
                              className="button my-3"
                              type="button"
                            >
                              {t('Confirm My Booking')}
                            </LoadingButton>
                          </Col>
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    className={styles.BookingConfirmationDetails__rowContainer}
                  >
                    {fetchingBookings ? (
                      <Skeleton height={800} containerTestId="skeleton" />
                    ) : (
                      <>
                        {bookings.map((booking, i) => (
                          <div key={booking.id} className="pagebreak py-4">
                            <div
                              className={`${
                                styles.BookingsConfirmation__container
                              } ${
                                booking.status === CANCELLED
                                  ? styles.BookingsConfirmation__cancelled
                                  : ''
                              }`}
                            >
                              <BookingConfirmationDetails
                                booking={booking}
                                pendingAddonsActions={pendingAddonsActions}
                                bookingIndex={i}
                                bookingsLength={bookings.length}
                                defaultExpanded={
                                  i === 0 && booking.status !== CANCELLED
                                }
                                onAddAddon={handleAddAddon}
                                onRemoveAddon={handleRemoveAddon}
                              />
                            </div>
                          </div>
                        ))}
                        {bookings.length > 1 && (
                          <TotalPriceBox bookings={bookings} />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>

              {hasSomeBookings && isLargeDesktop && (
                <Col xl="4">
                  {fetchingBookings && (
                    <Skeleton height={420} containerTestId="skeleton" />
                  )}

                  {displayBookingActions && (
                    <BookingsConfirmationActionButtons
                      bookings={bookings}
                      bookingsConfirmationViewRef={bookingsConfirmationViewRef}
                      cancelBookingsError={cancelBookingsError}
                      cancelBookingsLoading={cancelBookingsLoading}
                      onEditBooking={handleEditBooking}
                      onCancelBooking={handleCancelBooking}
                      actionableBookings={actionableBookings}
                    />
                  )}

                  {editingAddons && (
                    <div className="sticky-top py-4">
                      <BookingsCart
                        status={editingAddons ? EDIT_ADDONS : STAND_BY}
                        bookings={bookings}
                        currentBookingId={currentBookingId}
                        pendingAddonsActions={pendingAddonsActions}
                        error={addonsError}
                        addonsLoading={addonsLoading}
                        onRemoveAddon={handleRemoveAddon}
                        onConfirmChangesAddons={
                          handleBookingsCartConfirmChangesAddons
                        }
                        onDiscardChangesAddons={
                          handleBookingsCartDiscardChangesAddons
                        }
                      />
                    </div>
                  )}
                </Col>
              )}
            </>
          </Row>
        </div>
      </div>
    </>
  );
};

export default BookingsConfirmation;

//make a bookingsToModify and then update it from the bookingConfirmationDetails
