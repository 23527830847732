import { createBrowserRouter } from 'react-router-dom';
import Root from '../Root';
import GeneralViewWrapper from '../layout/GeneralViewWrapper/GeneralViewWrapper';
import MultiOrSingleHotelViewWrapper from '../layout/MultiOrSingleHotelViewWrapper/MultiOrSingleHotelViewWrapper';
import SingleHotelViewWrapper from '../layout/SingleHotelViewWrapper/SingleHotelViewWrapper';
import BookingsConfirmation from '../views/BookingsConfirmation/BookingsConfirmation';
import ErrorView from '../views/ErrorView/ErrorView';
import FindReservation from '../views/FindYourReservation/FindReservation';
import GuestForm from '../views/GuestForm/GuestForm';
import LoginRedirect from '../views/LoginRedirect/LoginRedirect';
import Maintenance from '../views/Maintenance/Maintenance';
import SelectHotel from '../views/SelectHotel/SelectHotel';
import SelectRoom from '../views/SelectRoom/SelectRoom';
import SelectTrain from '../views/SelectTrain/SelectTrain';
import ErrorBoundary from './components/ErrorBoundry';

export default createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    ),
    errorElement: <ErrorView />,
    children: [
      {
        element: <SingleHotelViewWrapper />,
        children: [
          {
            path: '/maintenance',
            element: <Maintenance />,
          },
          {
            path: '/select-room',
            element: <SelectRoom />,
          },
          {
            path: '/edit-room',
            element: <SelectRoom editing={true} />,
          },
        ],
      },
      {
        element: <MultiOrSingleHotelViewWrapper />,
        children: [
          {
            path: '/confirmation',
            element: <BookingsConfirmation />,
          },
          { path: '/checkout', element: <GuestForm /> },
        ],
      },
      {
        element: <GeneralViewWrapper />,
        children: [
          { path: '/', element: <SelectHotel /> },
          {
            path: '/select-train-journey',
            element: <SelectTrain />,
          },
          {
            path: '/findreservation',
            element: <FindReservation />,
          },
        ],
      },
      {
        path: '*',
        element: <ErrorView />,
      },
    ],
  },
  {
    element: (
      <ErrorBoundary>
        <LoginRedirect />
      </ErrorBoundary>
    ),
    errorElement: <ErrorView />,
    path: '/login/callback',
  },
]);
