import useDetermineMultipleHotels from '../../hooks/useDetermineMultipleHotels/useDetermineMultipleHotels';
import GeneralViewWrapper from '../GeneralViewWrapper/GeneralViewWrapper';
import SingleHotelViewWrapper from '../SingleHotelViewWrapper/SingleHotelViewWrapper';

const MultiOrSingleHotelViewWrapper = () => {
  const multipleHotels = useDetermineMultipleHotels();
  // TODO: there are views where if won't work as expected. For exmaple in /confirmation we cannot know if it is multi because we don't have bookings in redux
  return (
    <>{multipleHotels ? <GeneralViewWrapper /> : <SingleHotelViewWrapper />}</>
  );
};

export default MultiOrSingleHotelViewWrapper;
