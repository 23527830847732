import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import { useScreenReady } from '../../context/use-screen-ready';
import { CHINA_VERSION } from '../../globals';
import useAxios from '../../hooks/useAxios/useAxios';
import { setCurrentCurrency } from '../../redux/slices/appSettingsSlice/appSettingsSlice';
import { fetchHotel } from '../../redux/slices/belmondSlice/belmondSlice';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

const SingleHotelViewWrapper = () => {
  const { isScreenReady } = useScreenReady();
  const axios = useAxios();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hotelFetched, setHotelFetched] = useState(false);
  const { pathname } = useLocation();

  const bookingProductCode = useSelector((state) =>
    state.bookings.list.length ? state.bookings.list[0].productCode : null
  );
  const currencySelectedByUser = useSelector(
    (state) => state.appSettings.currencies.selectedByUser
  );
  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const productCode = useRef(
    searchParams.get('productCode') || bookingProductCode
  );

  const hotel =
    useSelector((state) => state.belmond.hotels[productCode.current]) || {};

  useEffect(() => {
    if (!currencySelectedByUser) {
      let currencyToUse = CHINA_VERSION ? 'CNY' : hotel?.currency || 'GBP';
      dispatch(setCurrentCurrency(currencyToUse));
    }
  }, [currencySelectedByUser, dispatch, hotel?.currency]);

  useEffect(() => {
    const fetchHotelData = async () => {
      if (!productCode.current) {
        navigate('/');
        return;
      }

      if (!countryCode) return;

      try {
        const { maintenance } = await dispatch(
          fetchHotel(productCode.current, axios, countryCode)
        );

        setHotelFetched(true);

        if (maintenance && pathname !== `/maintenance/${productCode.current}`) {
          navigate(`/maintenance?productCode=${productCode.current}`);
        }
      } catch (error) {
        console.error('Error fetching hotel data:', error);
        // Handle error appropriately, e.g., show an error message to the user
      }
    };

    fetchHotelData();
  }, [dispatch, navigate, pathname, axios, countryCode]);

  return (
    <div data-testid="single-hotel-view">
      <Header
        phone={hotel?.phone}
        hotelCurrency={hotel?.currency}
        productCode={productCode.current}
      />
      {hotelFetched && (
        <div style={{ display: isScreenReady ? 'block' : 'none' }}>
          <Outlet context={{ hotel }} />
        </div>
      )}

      {(!isScreenReady || !hotelFetched) && <ViewLoader />}

      <Footer
        loading={!hotelFetched}
        instagramLink={hotel?.metadata?.instagramLink}
        facebookLink={hotel?.metadata?.facebookLink}
        address={`${hotel?.address?.address}, ${hotel?.address?.city}, ${hotel?.address?.postalCode} `}
        phone={hotel?.phone}
        productCode={productCode.current}
        contactUsLink={hotel?.metadata?.contactUsLink}
        findUsLink={hotel?.metadata?.findUsLink}
      />
    </div>
  );
};

export default SingleHotelViewWrapper;
