const getLowestHotelRates = async (
  axios,
  moment,
  hotels,
  month,
  printPrice,
  { adults, children },
  countryCode
) => {
  const params = {
    productCode: hotels,
    month: moment(month).format('YYYY-MM'),
    numAdults: adults,
    children: children,
    countryCode,
  };

  const { data: propertyRates } = await axios.get('/calendar-availability', {
    params,
  });
  const lowestRatePricePerHotelObj = {};

  Object.keys(propertyRates).forEach((property) => {
    const prices = propertyRates[property]
      .map((item) => {
        if (!item.minimumPrice?.value) {
          return null;
        }
        const { numericPriceWithoutConversion, price } = printPrice(
          {
            value: item.minimumPrice.value,
            valueExcludingTaxesAndFees:
              item.minimumPrice.valueExcludingTaxesAndFees,
            productCode: property,
          },
          true
        );
        return { numericPriceWithoutConversion, price };
      })
      .filter((price) => price !== null);

    const { numericPriceWithoutConversion: minPrice } =
      prices.length > 0
        ? prices.reduce((acc, curr) => {
            if (
              acc.numericPriceWithoutConversion >
              curr.numericPriceWithoutConversion
            ) {
              return curr;
            }
            return acc;
          }, prices[0])
        : {};

    lowestRatePricePerHotelObj[property] = minPrice;
  });
  return lowestRatePricePerHotelObj;
};

export default getLowestHotelRates;
