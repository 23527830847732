import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotelsRateContent } from '../../redux/slices/belmondSlice/belmondSlice';
import useAxios from '../useAxios/useAxios';
import useLocalisedMoment from '../useLocalisedMoment/useLocalisedMoment';

const useRateContent = (
  rateCode,
  productCode,
  { startDate, endDate, numAdults = 2, agentId },
  isBooking
) => {
  const dispatch = useDispatch();
  const moment = useLocalisedMoment();
  const axios = useAxios();
  const [fetched, setFetched] = useState(false);

  const currentLanguage = useSelector(
    (state) => state.appSettings.languages.current
  );

  const [rateContent, setRateContent] = useState({});
  const [loading, setLoading] = useState(false);

  const rateContentData = useSelector(
    (state) => state.belmond.hotelsRateContent
  );

  const bookingRateContentData = useSelector(
    (state) => state.belmond.bookingRateContent
  );

  useEffect(() => {
    (async () => {
      if (!rateCode || !productCode) return;

      const params = {
        rateCode,
        startDate,
        endDate,
        numAdults,
        productCode,
        agentId,
        lang: currentLanguage,
      };

      const contentSource = isBooking
        ? bookingRateContentData
        : rateContentData;

      if (contentSource[productCode]?.[rateCode]) {
        setRateContent(contentSource[productCode][rateCode]);
      }

      const isFetching = Boolean(
        localStorage.getItem(`fetching-${productCode}-${rateCode}`)
      );

      const lastFetched = moment(
        contentSource[productCode]?.[rateCode]?.lastFetched
      );
      const now = moment();
      const diff = contentSource[productCode]?.[rateCode]?.lastFetched
        ? now.diff(lastFetched, 'minutes')
        : 6;

      const differentParams =
        JSON.stringify(
          contentSource[productCode]?.[rateCode]?.lastFetchedParams
        ) !== JSON.stringify(params);

      const isCached =
        contentSource[productCode]?.[rateCode] &&
        diff < 5 &&
        agentId === contentSource[productCode]?.[rateCode].agentId;

      if ((differentParams || !isCached) && !isFetching) {
        setLoading(true);
        setFetched(true);
        try {
          await dispatch(
            fetchHotelsRateContent(params, moment, axios, isBooking)
          );
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [
    dispatch,
    numAdults,
    productCode,
    rateCode,
    rateContentData,
    bookingRateContentData,
    moment,
    axios,
    currentLanguage,
    fetched,
    agentId,
    startDate,
    endDate,
    isBooking,
  ]);

  return { rateContent, loading };
};

export default useRateContent;
