import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import AvailabilityWarningModal from '../../components/AvailabilityWarningModal/AvailabilityWarningModal';
import BookingsCart from '../../components/BookingsCart/BookingsCart';
import BookingsCartMobileBar from '../../components/BookingsCartMobileBar/BookingsCartMobileBar';
import EditBookingBox from '../../components/EditBookingBox/EditBookingBox';
import NearbyProductsList from '../../components/NearbyProperties/NearbyProductsList';
import RoomsNotAvailableMessage from '../../components/RoomsNotAvailableMessage/RoomsNotAvailableMessage';
import RoomsNotMatchingFiltersMessage from '../../components/RoomsNotMatchingFiltersMessage/RoomsNotMatchingFiltersMessage';
import SearchRoomAlert from '../../components/SearchRoomAlert/SearchRoomAlert';
import { useScreenReady } from '../../context/use-screen-ready';
import {
  gtmAddToCart,
  gtmBookingSort,
  gtmBookingStepProgress,
  gtmPageView,
} from '../../gtm/events';
import validateAgentCode from '../../helpers/validate-agent-code';
import useAxios from '../../hooks/useAxios/useAxios';
import useLocalisedMoment from '../../hooks/useLocalisedMoment/useLocalisedMoment';
import useMessage from '../../hooks/useMessage/useMessage';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { fetchCalendarAvailability } from '../../redux/slices/availabilitySlice/availabilitySlice';
import {
  addBooking,
  bookingsCartStatuses,
  editCurrentBooking,
  fetchAgentProfile,
  resetBookingsState,
  setAgentProfileRatePlanAssociated,
} from '../../redux/slices/bookingsSlice/bookingsSlice';
import { resetGuestFormState } from '../../redux/slices/guestFormSlice/guestFormSlice';
import { resetPaymentState } from '../../redux/slices/paymentSlice/paymentSlice';
import { fetchGuestProfile } from '../../redux/slices/userSlice/userSlice';
import { MultiRoomPicker } from './components';
import AllStaysIncludedBox from './components/AllStaysIncludedBox/AllStaysIncludedBox';
import DisplayOptionsPanel from './components/Filters/DisplayOptionsPanel';
import RoomCategoriesList from './components/RoomCategoriesList/RoomCategoriesList';
import RoomRatesList from './components/RoomRatesList/RoomRatesList';
import SelectRoomMobileBar from './components/SelectRoomMobileBar/SelectRoomMobileBar';
import SelectRoomPicker from './components/SelectRoomPicker/SelectRoomPicker';
import useDetermineSelectRoomSearchParameters from './hooks/useDetermineSelectRoomSearchParameters';

const { EDIT_BOOKING, ADD_NEW_BOOKING } = bookingsCartStatuses;

const SelectRoom = ({ editing = false }) => {
  const { isScreenReady, setIsScreenReady } = useScreenReady();
  const { hotel } = useOutletContext();
  const { defaultValues, validateSpecialCode, validateRateCode } =
    useDetermineSelectRoomSearchParameters(hotel);
  const {
    t,
    constants: { errorMessage },
  } = useTranslate();
  const moment = useLocalisedMoment();
  const [Message, showMessage, closeMessage] = useMessage();
  const { printPrice } = usePrintPrice(hotel.productCode);

  const selectedCurrency = useSelector(
    (state) => state.appSettings.currencies.current
  );

  const { isLargeDesktop, isDesktop } = useScreenDetector();
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialSearch = useRef(false);

  const initialSearchSpecialCode = useRef(false);
  const initialSearchRateCode = useRef(false);

  const form = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const defaultDisplayOptions = {
    displayType: 'Rooms',
    sortBy: 'Recommended',
    filters: {
      roomCategory: searchParams.get('roomCategory')?.split(',') || [],
      roomType:
        searchParams.get('room')?.split(',') ||
        searchParams.get('roomType')?.split(',') ||
        [],
      roomView: searchParams.get('roomView')?.split(',') || [],
      minPrice: null,
      maxPrice: null,
    },
  };

  const [screenReadyCount, setScreenReadyCount] = useState(0);
  const [displayType, setDisplayType] = useState(
    defaultDisplayOptions.displayType
  );
  const [sortBy, setSortBy] = useState(defaultDisplayOptions.sortBy);
  const [roomFilters, setRoomFilters] = useState(defaultDisplayOptions.filters);
  const [loadingRooms, setLoadingRooms] = useState(false);
  const [numBookingNights, setNumBookingNights] = useState(0);
  const [availableFilters, setAvailableFilters] = useState(null);
  const [roomTypeAvailabilityList, setRoomTypeAvailabilityList] = useState([]);
  const [showMultiRooms, setShowMultiRooms] = useState(
    getValues('guestsPerRoom')?.length > 1
  );
  const [nearbyProducts, setNearbyProducts] = useState({});
  const [showRoomsNotAvailableError, setShowRoomsNotAvailableError] =
    useState(false);
  const [
    showRoomsNotMatchingFiltersError,
    setShowRoomsNotMatchingFiltersError,
  ] = useState(false);

  const [specialCodeApplied, setSpecialCodeApplied] = useState(
    !!defaultValues.specialCodeValue
  );

  const [rateCodeApplied, setRateCodeApplied] = useState(
    !!defaultValues.rateCode
  );

  const [agentIdApplied, setAgentIdApplied] = useState(false);
  const [lowerRatePrice, setLowerRatePrice] = useState(null);
  const [higherRatePrice, setHigherRatePrice] = useState(null);

  const bookings = useSelector((state) => state.bookings.list);
  const accessToken = useSelector((state) => state.user.accessToken);

  const { currentMonth: currentCalendarMonth, isOpen: isCalendarOpen } =
    useSelector((state) => state.availability.calendar);

  const currentBooking = useSelector((state) =>
    state.bookings.list.find((b) => b.id === state.bookings.current)
  );

  const agentProfileRatePlanAssociated = useSelector(
    (state) => state.bookings.agentProfileRatePlanAssociated
  );

  const allStaysInclude = useSelector(
    (state) => state.belmond.hotels[hotel.productCode]?.allStaysInclude
  );

  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const guestsPerRoom = watch('guestsPerRoom');

  const numAdultsPerRoom = useMemo(
    () =>
      guestsPerRoom.reduce((prev, room) => [...prev, room.numAdults || 0], []),
    [guestsPerRoom]
  );

  const childrenAgesPerRoom = useMemo(
    () =>
      guestsPerRoom.reduce((prev, room) => [...prev, room.children || []], []),
    [guestsPerRoom]
  );

  const agentId = watch('agentId');
  const specialCodeType = watch('specialCodeType');
  const specialCodeValue = watch('specialCodeValue');
  const rateCode = watch('rateCode');
  const startDate = watch('startDate');

  const currentBookingIndex = bookings.findIndex(
    (booking) => booking.id === currentBooking?.id
  );

  const [highlightedRoomIndex, setHighlightedRoomIndex] = useState(() => {
    if (editing) return currentBookingIndex === -1 ? 0 : currentBookingIndex;
    if (currentBookingIndex < 0) return 0;
    if (guestsPerRoom.length > bookings.length) return bookings.length + 1;
    return 0;
  });

  // fetch calendar availability if any of the params change
  useEffect(() => {
    if (!countryCode) return;

    const params = {
      numAdults: numAdultsPerRoom,
      children: childrenAgesPerRoom,
      specialCodeType,
      specialCodeValue,
      agentId,
      rateCode,
    };

    const monthToFetch =
      currentCalendarMonth || moment(startDate).format('YYYY-MM');

    isScreenReady &&
      dispatch(
        fetchCalendarAvailability(
          hotel.productCode,
          monthToFetch,
          params,
          moment,
          axios,
          countryCode
        )
      );

    // or if the availability is stale (15 mins)
    const checkCalendarAvailabilityIsStale = setInterval(() => {
      dispatch(
        fetchCalendarAvailability(
          hotel.productCode,
          monthToFetch,
          params,
          moment,
          axios,
          countryCode
        )
      );
    }, 60000); // check every minute

    return () => {
      clearInterval(checkCalendarAvailabilityIsStale);
    };
  }, [
    isCalendarOpen,
    agentId,
    agentProfileRatePlanAssociated,
    childrenAgesPerRoom,
    currentCalendarMonth,
    dispatch,
    hotel.productCode,
    numAdultsPerRoom,
    specialCodeType,
    specialCodeValue,
    startDate,
    moment,
    isScreenReady,
    axios,
    rateCode,
    countryCode,
  ]);

  useEffect(() => {
    const roomResults = roomTypeAvailabilityList[highlightedRoomIndex];

    if (roomResults?.length) {
      const uniqueRoomRatePrices = [
        ...new Set(
          roomResults
            ?.map(({ rates }) =>
              rates.map(
                ({ pricesPerNight }) =>
                  pricesPerNight.reduce((a, b) => {
                    // use the users currency as the slider values
                    const { numericPrice } = printPrice({
                      value: b.price + b.tax + b.serviceCharge,
                      valueExcludingTaxesAndFees: b.price,
                    });

                    return a + Math.round(numericPrice);
                  }, 0) / pricesPerNight.length
              )
            )
            .flat()
        ),
      ];

      uniqueRoomRatePrices.sort((a, b) => a - b);
      const newLowerRatePrice = Math.min(Math.ceil(uniqueRoomRatePrices[0]));
      const newHigherRatePrice = Math.ceil(
        uniqueRoomRatePrices[uniqueRoomRatePrices.length - 1]
      );

      if (lowerRatePrice === null || newLowerRatePrice !== lowerRatePrice) {
        setLowerRatePrice(newLowerRatePrice);
      }
      if (higherRatePrice === null || newHigherRatePrice !== higherRatePrice) {
        setHigherRatePrice(newHigherRatePrice);
      }
    }
  }, [
    roomTypeAvailabilityList,
    highlightedRoomIndex,
    lowerRatePrice,
    higherRatePrice,
    printPrice,
    selectedCurrency,
  ]);

  const filterRoomTypeAvailabilityList = useCallback(() => {
    const list = cloneDeep(
      roomTypeAvailabilityList[highlightedRoomIndex] || []
    );

    //filtering by "conditions" if all conditions are true, then the room will be displayed
    const filteredList = list.filter((room) => {
      const { roomCategory, roomView, roomType, minPrice, maxPrice } =
        roomFilters;
      const conditions = [];

      if (roomCategory?.length) {
        conditions.push(roomCategory.includes(room.category.code));
      }

      if (roomType?.length) {
        conditions.push(roomType.includes(room.code));
      }

      if (roomView?.length) {
        conditions.push(
          room.views.some((view) => roomView.includes(view.code))
        );
      }

      if (
        minPrice !== null &&
        maxPrice !== null &&
        (minPrice !== lowerRatePrice || maxPrice !== higherRatePrice)
      ) {
        room.rates = room.rates.filter((rate) => {
          const totalPrice = rate.pricesPerNight.reduce(
            (a, b) =>
              a +
              Math.round(
                printPrice({
                  value: b.price + b.tax + b.serviceCharge,
                  valueExcludingTaxesAndFees: b.price,
                }).numericPrice
              ),
            0
          );
          const numNights = rate.pricesPerNight.length;
          const avgRatePrice = Math.ceil(totalPrice / numNights);
          return (
            avgRatePrice >= Number(minPrice) && avgRatePrice <= Number(maxPrice)
          );
        });

        conditions.push(!!room.rates.length);
      }

      return conditions.every((condition) => condition);
    });

    return filteredList;
  }, [
    roomTypeAvailabilityList,
    highlightedRoomIndex,
    roomFilters,
    lowerRatePrice,
    higherRatePrice,
    printPrice,
  ]);

  const roomTypesResults = useMemo(() => {
    // TODO:  if a room has already been selected in this list, move it to the top
    const filteredList = filterRoomTypeAvailabilityList();
    if (sortBy === 'Highest Price') {
      return [...filteredList].sort(
        (a, b) => b.rates[0].price - a.rates[0].price
      );
    } else
      return [...filteredList].sort(
        (a, b) => a.rates[0].price - b.rates[0].price
      );
  }, [filterRoomTypeAvailabilityList, sortBy]);

  const getLowestRateObject = ([productCode, rooms]) => {
    const minRoomPrice = Math.min(...rooms.map((room) => room.rates[0].price));
    return { productCode, minRoomPrice };
  };

  //TODO Remove the caorusel in multiroom if there is no availability
  const fetchNearbyProducts = useCallback(
    async (params) => {
      try {
        // .split will return an array with an empty string if the string is empty
        // prevent this by checking if the values length
        const nearbyProducts = hotel.nearbyProducts.length
          ? hotel.nearbyProducts.split(',')
          : [];

        const roomAvailabilityRequests = nearbyProducts.map((productCode) =>
          axios.get('/room-availability', {
            params: { ...params, productCode },
          })
        );

        const res = await Promise.all(roomAvailabilityRequests);
        const availabilityList = res.map((response) => response.data);

        params.adults = params.numAdults;
        delete params.numAdults;

        // get availability data to find the minimum room prices
        const hotels = availabilityList.flatMap((availability) =>
          Object.entries(availability)
            .filter(([_, rooms]) => rooms.length > 0)
            .map(getLowestRateObject)
        );

        setNearbyProducts({ hotels, params });
      } catch (e) {
        //TODO
        console.log(e);
      }
    },
    [axios, hotel.nearbyProducts]
  );

  const fetchRooms = useCallback(
    async (formData, useAgentId) => {
      const {
        startDate,
        endDate,
        guestsPerRoom,
        agentId,
        specialCodeType,
        specialCodeValue,
        rateCode,
      } = formData;
      closeMessage();
      setSpecialCodeApplied(!!specialCodeValue);
      setRateCodeApplied(!!rateCode);
      setAgentIdApplied(!!agentId);
      setShowRoomsNotAvailableError(false);
      setShowRoomsNotMatchingFiltersError(false);
      setNearbyProducts(false);
      setLoadingRooms(true);
      setRoomTypeAvailabilityList([]);
      try {
        setNumBookingNights(
          Math.ceil(
            (new Date(endDate).getTime() - new Date(startDate).getTime()) /
              (1000 * 3600 * 24)
          )
        );

        const roomAvailabilityRequests = [];
        for (const roomIndex of Array.from(
          { length: guestsPerRoom.length },
          (_, i) => i
        )) {
          const params = {
            startDate,
            endDate,
            productCode: hotel.productCode,
            specialCodeType,
            specialCodeValue,
            rateCode,
            agentId: useAgentId ? agentId : '',
            numAdults: guestsPerRoom[roomIndex].numAdults,
            children: guestsPerRoom[roomIndex].children,
          };

          roomAvailabilityRequests.push(
            axios.get('/room-availability', {
              params,
            })
          );
        }

        const res = await Promise.all(roomAvailabilityRequests);
        const availabilityList = res.map(
          (element) => element.data[hotel.productCode]
        );

        if (availabilityList[0].length === 0) {
          await fetchNearbyProducts({
            startDate,
            endDate,
            specialCodeType,
            specialCodeValue,
            agentId: useAgentId ? agentId : '',
            numAdults: guestsPerRoom[0].numAdults,
            children: guestsPerRoom[0].children,
          });
        }

        setRoomTypeAvailabilityList(availabilityList);
      } catch (e) {
        showMessage(errorMessage, 'danger');
      } finally {
        setLoadingRooms(false);
      }
    },
    [
      closeMessage,
      hotel.productCode,
      axios,
      fetchNearbyProducts,
      showMessage,
      errorMessage,
    ]
  );

  const handleFormSubmit = useCallback(
    async (
      {
        startDate,
        endDate,
        specialCodeType,
        specialCodeValue,
        guestsPerRoom,
        agentId,
        rateCode,
      },
      { ratePlanAssociated } = { ratePlanAssociated: false }
    ) => {
      const useAgentId =
        ratePlanAssociated === false
          ? ratePlanAssociated
          : agentProfileRatePlanAssociated;

      fetchRooms(
        {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          agentId,
          specialCodeType,
          specialCodeValue,
          guestsPerRoom,
          rateCode,
        },
        useAgentId
      );

      reset({
        startDate,
        endDate,
        specialCodeType,
        specialCodeValue,
        guestsPerRoom,
        agentId,
        agentCrmId: defaultValues.agentCrmId,
        rateCode: defaultValues.rateCode,
      });
    },

    [
      fetchRooms,
      agentProfileRatePlanAssociated,
      reset,
      defaultValues.agentCrmId,
      defaultValues.rateCode,
      moment,
    ]
  );

  const handleFiltersSortByChange = useCallback((sortBy) => {
    gtmBookingSort(sortBy);
    setSortBy(sortBy);
  }, []);

  const handleFiltersChange = ({
    roomCategory,
    roomType,
    roomView,
    minPrice,
    maxPrice,
  }) => {
    // convert the prices to the hotels currency
    setRoomFilters({ roomCategory, roomType, roomView, minPrice, maxPrice });
  };

  const handleNextPage = useCallback(() => {
    if (hotel.addonsBeforeCheckout) navigate('/addons');
    else navigate('/checkout');
  }, [hotel.addonsBeforeCheckout, navigate]);

  const handleBook = useCallback(
    async (roomType, roomRate, gtmView) => {
      const { numAdults, children } = guestsPerRoom[highlightedRoomIndex];
      const {
        startDate,
        endDate,
        specialCodeType,
        specialCodeValue,
        agentId,
        agentCrmId,
      } = getValues();

      const booking = {
        roomType,
        roomRate,
        productCode: hotel.productCode,
        startDate,
        endDate,
        numAdults,
        children,
        specialCodeType,
        specialCodeValue,
        agentId,
        agentCrmId: agentId && agentCrmId ? agentCrmId : null,
      };

      gtmAddToCart(gtmView, booking, hotel);

      if (currentBooking) {
        dispatch(editCurrentBooking(booking));
      } else {
        dispatch(addBooking(booking));
      }

      const allRoomsSelected =
        guestsPerRoom.length === bookings.length + (currentBooking ? 0 : 1); // +1 because we are adding a new booking

      if (!allRoomsSelected) {
        setHighlightedRoomIndex((prev) => prev + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }

      if (allRoomsSelected && editing) {
        handleNextPage();
      }
    },
    [
      guestsPerRoom,
      highlightedRoomIndex,
      getValues,
      hotel,
      currentBooking,
      bookings.length,
      editing,
      dispatch,
      handleNextPage,
    ]
  );

  const handleMultiRoomFocusChange = (index) => {
    if (!isDesktop) setHighlightedRoomIndex(index);
  };

  const handleDisplayTypeChange = useCallback((displayType) => {
    setDisplayType(displayType);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const now = moment();
      const start = moment(startDate);
      const isInThePast = now.isAfter(start, 'day');
      if (isInThePast) {
        setValue('startDate', moment().format('YYYY-MM-DD'));
        setValue('endDate', moment().add(1, 'day').format('YYYY-MM-DD'));
        trigger();
        handleSubmit(handleFormSubmit)();
      }
    }, 30000);

    return () => clearTimeout(timeout);
  }, [startDate, trigger, setValue, handleSubmit, handleFormSubmit, moment]);

  // if editing (on /edit-room) and no bookings, redirect to /select-room
  useEffect(() => {
    if (editing && !bookings.length) {
      navigate(`/select-room?productCode=${hotel.productCode}`);
    }
  }, [bookings.length, editing, hotel.productCode, navigate]);

  useEffect(() => {
    setAvailableFilters(hotel.filters);
  }, [hotel.filters, roomTypesResults]);

  useEffect(() => {
    if (
      (!editing && currentBooking && currentBooking.status !== 'UNSUBMITTED') ||
      (bookings.length && bookings[0].productCode !== hotel.productCode)
    ) {
      dispatch(resetGuestFormState());
      dispatch(resetBookingsState());
      dispatch(resetPaymentState());
    }
  }, [currentBooking, dispatch, editing, hotel.productCode, bookings]);

  useEffect(() => {
    if (!initialSearch.current) {
      if (defaultValues.agentId) {
        validateAgentCode(
          {
            productCode: hotel.productCode,
            startDate: defaultValues.startDate,
            endDate: defaultValues.endDate,
            agentId: defaultValues.agentId,
            numAdults: defaultValues.guestsPerRoom[0].numAdults,
          },
          axios
        )
          .then(({ valid, ratePlanAssociated = false }) => {
            dispatch(setAgentProfileRatePlanAssociated(ratePlanAssociated));
            if (valid) {
              fetchRooms(defaultValues, ratePlanAssociated);
              setAgentIdApplied(true);
              dispatch(fetchAgentProfile(defaultValues.agentId, axios));
            } else {
              setAgentIdApplied(false);
              setValue('agentId', '');
              showMessage(
                t(
                  'It seems that the agent ID is not correct, please add it again or contact us.',
                  'danger'
                )
              );
            }
            setScreenReadyCount((prev) => prev + 1);
          })
          .catch((e) => setScreenReadyCount((prev) => prev + 1));
      } else {
        setScreenReadyCount((prev) => prev + 1);
      }
      initialSearch.current = true;
    }
  }, [
    defaultValues,
    fetchRooms,
    setValue,
    dispatch,
    showMessage,
    hotel.productCode,
    t,
    axios,
  ]);

  useEffect(() => {
    if (!initialSearchSpecialCode.current) {
      if (searchParams.get('specialCodeValue')) {
        validateSpecialCode().then(() => {
          setScreenReadyCount((prev) => prev + 1);
        });
      } else {
        setScreenReadyCount((prev) => prev + 1);
      }

      initialSearchSpecialCode.current = true;
    }
  }, [defaultValues.specialCodeValue, searchParams, validateSpecialCode]);

  useEffect(() => {
    if (!initialSearchRateCode.current) {
      if (searchParams.get('rateCode')) {
        validateRateCode().then(() => {
          setScreenReadyCount((prev) => prev + 1);
        });
      } else {
        setScreenReadyCount((prev) => prev + 1);
      }

      initialSearchRateCode.current = true;
    }
  }, [defaultValues.rateCode, searchParams, validateRateCode]);

  useEffect(() => {
    if (screenReadyCount === 3 && !isScreenReady) {
      setIsScreenReady(true);
      reset({
        startDate: defaultValues.startDate,
        endDate: defaultValues.endDate,
        specialCodeType: defaultValues.specialCodeType,
        specialCodeValue: defaultValues.specialCodeValue,
        guestsPerRoom: defaultValues.guestsPerRoom,
        agentId: defaultValues.agentId,
        agentCrmId: defaultValues.agentCrmId,
        rateCode: defaultValues.rateCode,
      });
      if (!agentIdApplied) {
        fetchRooms(defaultValues, false);
      }
    }
  }, [
    agentIdApplied,
    defaultValues,
    fetchRooms,
    reset,
    setIsScreenReady,
    isScreenReady,
    screenReadyCount,
  ]);

  useEffect(() => {
    if (accessToken) {
      // we fetch it again just in case there were some changes while we are on the website
      dispatch(fetchGuestProfile(accessToken, axios));
    }
  }, [accessToken, dispatch, axios]);

  useEffect(() => {
    if (hotel.address) {
      gtmPageView(hotel);
      gtmBookingStepProgress('Accommodation');
    }
  }, [hotel]);

  useEffect(() => {
    setShowRoomsNotAvailableError(false);
    setShowRoomsNotMatchingFiltersError(false);
    if (roomTypeAvailabilityList.length && !roomTypesResults.length) {
      if (
        roomTypesResults.length !==
        roomTypeAvailabilityList[highlightedRoomIndex]?.length
      ) {
        setShowRoomsNotMatchingFiltersError(true);
      } else if (!nearbyProducts.hotels?.length) {
        setShowRoomsNotAvailableError(true);
      }
    }
  }, [
    getValues,
    roomTypeAvailabilityList,
    roomTypesResults,
    highlightedRoomIndex,
    nearbyProducts.hotels?.length,
  ]);

  useEffect(() => {
    if (isSubmitting) {
      setShowMultiRooms(guestsPerRoom.length > 1);
    }
  }, [isSubmitting, guestsPerRoom]);

  useEffect(() => {
    if (bookings.length === guestsPerRoom.length && !editing) {
      handleNextPage();
    }
  }, [bookings.length, editing, handleNextPage, guestsPerRoom]);

  // if we remove a room, ensure highlightedRoomIndex is not skipping a slot
  useEffect(() => {
    if (bookings.length < highlightedRoomIndex) {
      setHighlightedRoomIndex(bookings.length);
    }
  }, [bookings.length, highlightedRoomIndex]);

  useEffect(() => {
    if (showRoomsNotAvailableError || nearbyProducts.hotels?.length) {
      setAvailableFilters(null);
      setShowMultiRooms(false);
      setAgentIdApplied(false);
      setSpecialCodeApplied(false);
    }
  }, [nearbyProducts.hotels?.length, showRoomsNotAvailableError]);

  useEffect(() => {
    document.title = t('Select Accomodation');
  }, [t]);

  return (
    <>
      <AvailabilityWarningModal />
      <FormProvider {...form}>
        <div
          style={{
            boxShadow:
              isDesktop && '0 -8px white,0 4px 12px 0px rgb(0 0 0 / 8%)',
          }}
        >
          <Form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
            {!!bookings.length && !isLargeDesktop && (
              <BookingsCartMobileBar
                selectRoomForm={form}
                bookings={bookings}
                status={editing ? EDIT_BOOKING : ADD_NEW_BOOKING}
                currentBookingId={currentBooking?.id}
              />
            )}
            {isDesktop ? (
              <SelectRoomPicker
                productCode={hotel.productCode}
                loading={loadingRooms}
                handleFormSubmit={handleFormSubmit}
              />
            ) : (
              <SelectRoomMobileBar
                onPickerSubmit={handleSubmit(handleFormSubmit)}
                productCode={hotel.productCode}
              />
            )}
          </Form>
        </div>
      </FormProvider>
      <div className="container-xxl w-100">
        <Row
          className="d-flex flex-row-reverse gx-3"
          style={{
            marginTop: isDesktop ? 10 : 0,
          }}
        >
          {isLargeDesktop && (
            <Col xl="4" className="d-block">
              <div
                className="sticky-top"
                style={{ padding: '22px 0 33px 20px' }}
              >
                {!!bookings.length && (
                  <BookingsCart
                    selectRoomForm={form}
                    bookings={bookings}
                    status={editing ? EDIT_BOOKING : ADD_NEW_BOOKING}
                    currentBookingId={currentBooking?.id}
                    highlightedRoomIndex={highlightedRoomIndex}
                  />
                )}
                {allStaysInclude?.headers?.length ? (
                  <AllStaysIncludedBox productCode={hotel.productCode} />
                ) : null}
              </div>
            </Col>
          )}
          <Col xl="8" style={{ marginTop: 15 }}>
            <div>
              <Row>
                <Col className="p-0">
                  {showMultiRooms && (
                    <MultiRoomPicker
                      editing={editing}
                      guestsPerRoom={guestsPerRoom}
                      highlightedRoomIndex={highlightedRoomIndex}
                      isSubmitting={form.formState.isSubmitting}
                      onFocusChange={handleMultiRoomFocusChange}
                      onHighlightRoom={(roomIndex) => {
                        setHighlightedRoomIndex(roomIndex);
                      }}
                    />
                  )}
                  {availableFilters && (
                    <DisplayOptionsPanel
                      defaultValues={defaultDisplayOptions}
                      lowerPriceAvailable={lowerRatePrice}
                      higherPriceAvailable={higherRatePrice}
                      availableFilters={availableFilters}
                      onSortByChange={handleFiltersSortByChange}
                      onFiltersChange={handleFiltersChange}
                      onDisplayTypeChange={handleDisplayTypeChange}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-2 g-0">
                <Message />
                {(specialCodeApplied || rateCodeApplied) && (
                  <SearchRoomAlert
                    title={t(
                      'The displayed price includes any applied promotional rate.'
                    )}
                    description={t('Prices shown include promotional rates.')}
                    type="success"
                  />
                )}

                {agentIdApplied && (
                  <SearchRoomAlert
                    title={t(
                      'The displayed price includes any applied promotional rate.'
                    )}
                    description={t('Prices show commission percentages.')}
                    type="success"
                  />
                )}

                {editing && <EditBookingBox currentBooking={currentBooking} />}
                {showRoomsNotAvailableError && <RoomsNotAvailableMessage />}
                {showRoomsNotMatchingFiltersError && (
                  <RoomsNotMatchingFiltersMessage />
                )}
                {nearbyProducts.hotels?.length ? (
                  <NearbyProductsList
                    products={nearbyProducts.hotels}
                    params={nearbyProducts.params}
                    productCode={hotel.productCode}
                  />
                ) : null}
                {displayType === 'Rooms' ? (
                  <RoomCategoriesList
                    loading={loadingRooms}
                    productCode={hotel.productCode}
                    onBook={handleBook}
                    roomTypesResults={roomTypesResults}
                    numBookingNights={parseInt(numBookingNights)}
                    selectRoomForm={form}
                  />
                ) : (
                  <RoomRatesList
                    productCode={hotel.productCode}
                    roomTypesResults={roomTypesResults}
                    numBookingNights={numBookingNights}
                    onBook={handleBook}
                    selectRoomForm={form}
                  />
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

SelectRoom.propTypes = {
  editing: PropTypes.bool,
};

export default SelectRoom;
