import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/images/icons/ArrowUp.svg';
import RoomRateModal from '../../../../components/RoomRateModal/RoomRateModal';
import { gtmBookingInteraction } from '../../../../gtm/events';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import RoomRate from '../RoomRate/RoomRate';
import RoomType from '../RoomType/RoomType';
import styles from './RoomCategoriesListItem.module.css';

const RoomCategoriesListItem = ({
  onBook,
  productCode,
  roomTypes,
  numBookingNights,
  renderSecondaryButton,
  selectRoomForm,
}) => {
  const { t } = useTranslate();
  const media = useSelector((state) => state.media.mediaUrls);
  const [ratesCollapsed, setRatesCollapsed] = useState(true);
  const [selectedRoomType, setSelectedRoomType] = useState(roomTypes[0]);
  const [roomRateModalOpen, setRoomRateModalOpen] = useState(null);
  const roomRateModalData = useRef(null); // we need to have this in 2 vars otherwise while modal is closing data will be shown as undefined
  const roomTypeRef = useRef(null);

  const specialCodeActive = ['COMP', 'DISC'].includes(
    selectRoomForm?.getValues('specialCodeValue')
  );

  const bookings = useSelector((state) => state.bookings.list);
  const handleLessRatesBtnClick = () => {
    setRatesCollapsed(true);
    roomTypeRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleSelectedRoomTypeChange = (roomType) => {
    setSelectedRoomType(roomType);
    gtmBookingInteraction('Room Type Box');
  };

  const imagesForSelectedRoomType = specialCodeActive
    ? media.filter((mediaItem) =>
        mediaItem.includes(`HeroImages/${productCode}`)
      )
    : media.filter((mediaItem) =>
        mediaItem.includes(`RoomImages/${productCode}/${selectedRoomType.code}`)
      );

  const videosForSelectedRoomType = media.filter((mediaItem) =>
    mediaItem.includes(
      `videos/RoomVideos/${productCode}/${selectedRoomType.code}`
    )
  );

  const handleMoreRates = () => {
    gtmBookingInteraction('More Rates');
    setRatesCollapsed(false);
  };

  const handleRateDetails = () => {
    gtmBookingInteraction('Rate Details');
    roomRateModalData.current = selectedRoomType.rates[0];
    setRoomRateModalOpen(true);
  };

  const handleSelectedRateDetails = (rate) => {
    gtmBookingInteraction('Rate Details');
    roomRateModalData.current = rate;
    setRoomRateModalOpen(true);
  };

  useEffect(() => {
    if (bookings.length > 0) {
      const selectedRoomCategory = roomTypes.find(
        (roomType) => roomType.code === bookings[0].roomType.code
      );
      if (selectedRoomCategory) {
        setSelectedRoomType(selectedRoomCategory);
      }
    } else {
      setSelectedRoomType(roomTypes[0]);
    }
  }, [roomTypes, bookings]);

  useEffect(() => {
    // this is because if for some reason (like when filtering) the rates length changes to 1, we need to collapse the rates
    if (selectedRoomType.rates.length === 1 && !ratesCollapsed) {
      setRatesCollapsed(true);
    }
  }, [selectedRoomType.rates.length, ratesCollapsed]);

  return (
    <>
      <RoomRateModal
        rate={roomRateModalData.current || {}}
        open={roomRateModalOpen}
        roomTypeTitle={selectedRoomType.title}
        categoryName={selectedRoomType.category.name}
        productCode={productCode}
        priceBeforeTax={roomRateModalData.current?.totalAmountBeforeTax}
        numBookingNights={numBookingNights}
        pricesPerNight={roomRateModalData.current?.pricesPerNight}
        showBookBtn
        selectRoomForm={selectRoomForm}
        onBookNowBtnClick={(gtmView) =>
          onBook(selectedRoomType, roomRateModalData.current, gtmView)
        }
        onClose={() => setRoomRateModalOpen(false)}
        roomTypeCode={selectedRoomType.code}
      />
      {/* this is a fix for preloading images so when changing the room type the image no need to load */}
      {roomTypes.map((roomType) => (
        <img
          src={
            media.filter((mediaItem) =>
              mediaItem.includes(`RoomImages/${productCode}/${roomType.code}`)
            )[0]
          }
          alt={roomType.title}
          style={{ height: '0', width: '0' }}
          key={`preload-image-${roomType.code}`}
        />
      ))}

      <RoomType
        ref={roomTypeRef}
        productCode={productCode}
        key={selectedRoomType.code}
        code={selectedRoomType.code}
        title={selectedRoomType.title}
        categoryName={selectedRoomType.category.name}
        roomTypes={roomTypes}
        sleeps={selectedRoomType.sleeps}
        images={imagesForSelectedRoomType}
        video={videosForSelectedRoomType[0]}
        bedding={selectedRoomType.bedding}
        size={selectedRoomType.size}
        shortDescription={selectedRoomType.shortDescription}
        longDescription={selectedRoomType.longDescription}
        onChangeSelectedRoomType={handleSelectedRoomTypeChange}
        specialCodeActive={specialCodeActive}
      />
      <div className={styles.RoomCategoriesListItem__ratesContainer}>
        <div
          className={
            ratesCollapsed
              ? styles.RoomCategoriesListItem__ratesCollapsed
              : styles.RoomCategoriesListItem__ratesExpanded
          }
        >
          {ratesCollapsed && !specialCodeActive && (
            <hr className="bbe-hr mb-3" />
          )}
          {ratesCollapsed && (
            <RoomRate
              selectRoomForm={selectRoomForm}
              rate={selectedRoomType.rates[0]}
              productCode={productCode}
              roomTypeCode={selectedRoomType.code}
              roomTypeTitle={selectedRoomType.title}
              numBookingNights={numBookingNights}
              onRateDetailsBtnClick={handleRateDetails}
              onBookNowBtnClick={(gtmView) =>
                onBook(selectedRoomType, selectedRoomType.rates[0], gtmView)
              }
              renderSecondaryButton={
                <>
                  {renderSecondaryButton}
                  {selectedRoomType.rates.length > 1 && (
                    <Button
                      className="button transparent"
                      onClick={handleMoreRates}
                      aria-label="View more rates"
                    >
                      {t('More Rates')}{' '}
                      <ArrowDownIcon style={{ marginLeft: '4px' }} />
                    </Button>
                  )}
                </>
              }
            />
          )}
          {selectedRoomType.rates.length > 1 && !ratesCollapsed && (
            <>
              {selectedRoomType.rates.map((rate, i) => (
                <div key={`${selectedRoomType.code}-${rate.code}`}>
                  <RoomRate
                    rate={rate}
                    selectRoomForm={selectRoomForm}
                    productCode={productCode}
                    roomTypeCode={selectedRoomType.code}
                    roomTypeTitle={selectedRoomType.title}
                    numBookingNights={numBookingNights}
                    onRateDetailsBtnClick={() =>
                      handleSelectedRateDetails(rate)
                    }
                    onBookNowBtnClick={(gtmView) =>
                      onBook(selectedRoomType, rate, gtmView)
                    }
                  />
                  <hr className="bbe-hr" />
                </div>
              ))}
              <div className="d-flex justify-content-center my-3">
                <Button
                  className="button transparent"
                  onClick={handleLessRatesBtnClick}
                  aria-label="View less rates"
                >
                  {t('Less Rates')}
                  <ArrowUpIcon
                    style={{ marginLeft: '6px', marginBottom: '2px' }}
                  />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

RoomCategoriesListItem.propTypes = {
  onBook: PropTypes.func.isRequired,
  productCode: PropTypes.string.isRequired,
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      sleeps: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      video: PropTypes.string,
      bedding: PropTypes.string.isRequired,
      size: PropTypes.object.isRequired,
      shortDescription: PropTypes.string.isRequired,
      longDescription: PropTypes.string.isRequired,
      rates: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number.isRequired,
          serviceCharge: PropTypes.number.isRequired,
          pricesPerNight: PropTypes.arrayOf(
            PropTypes.shape({
              date: PropTypes.string.isRequired,
              price: PropTypes.number.isRequired,
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  numBookingNights: PropTypes.number.isRequired,
  renderSecondaryButton: PropTypes.node,
  selectRoomForm: PropTypes.object,
};

export default RoomCategoriesListItem;
