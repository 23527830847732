import getSelectedRange from './get-selected-range';

const getAvailableSelectRange = (startDate, daysAvailability, moment) => {
  const datesArray = daysAvailability.sort(
    (a, b) =>
      moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD')
  );
  const indexOfSelectedDate = datesArray.findIndex(
    ({ date }) => date === startDate
  );
  if (indexOfSelectedDate !== -1) {
    for (let i = indexOfSelectedDate; i < datesArray.length; i++) {
      const { isCheckIn, metadata: { NoArrive } = {} } = datesArray[i];
      // if not a check in, and also not a pass through day, return the range here
      if (!isCheckIn && !NoArrive) {
        let missingDate = moment(datesArray[i]?.date).format('YYYY-MM-DD');
        return getSelectedRange(startDate, missingDate, moment);
      }
      if (i === datesArray.length - 1) {
        return getSelectedRange(
          startDate,
          moment(datesArray[i]?.date).format('YYYY-MM-DD'),
          moment
        );
      }
    }
  }
  return null;
};

export default getAvailableSelectRange;
