import { getDate, getMonth, getYear } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { Col, Input, Row } from 'reactstrap';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import useAxios from '../../../../hooks/useAxios/useAxios';
import useMessage from '../../../../hooks/useMessage/useMessage';
// import { setDateForTrains } from '../../../../redux/slices/reservationSlice/reservationSlice';
import './DatePicker.css';
import styles from './TrainDatePicker.module.css';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const TrainDatePicker = ({
  close,
  inline,
  cabins,
  productCode,
  packageId,
  departureStation,
  arrivalStation,
}) => {
  const axios = useAxios();
  const dateForTrains = useSelector((state) => state.reservation.dateForTrains);
  const [selectedDate, setSelectedDate] = useState();
  const [pickSpecificDate, setPickSpecificDate] = useState(true);
  const [startYear, setStartYear] = useState(getYear(new Date()));
  const [departingIndex, setDepartingIndex] = useState(0);
  // const dispatch = useDispatch();
  const [departingMonthYears, setDepartingMonthYears] = useState([]);
  const [yearsMonth, setYearsMonth] = useState({});
  const [Message, showMessage] = useMessage();
  const [datesPrices] = useState({});
  const [journeyOpenDates] = useState({});
  const { promiseInProgress: gettingAvailableDates } = usePromiseTracker({
    area: 'gettingAvailableDates',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [anchorId, setAnchorId] = useState('tooltip-anchor');

  const renderDayContents = (day, date) => {
    let price;
    if (inline) {
      let dayInMonth = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (month < 10) {
        month = '0' + month;
      }
      if (dayInMonth < 10) {
        dayInMonth = '0' + dayInMonth;
      } else {
        dayInMonth = '' + dayInMonth;
      }
      const yearMonthInString = year + '-' + month;
      if (datesPrices[yearMonthInString]) {
        price = datesPrices[yearMonthInString][dayInMonth];
      }
    }
    return (
      <>
        <div
          className={styles.TrainDatePicker__date}
          onMouseEnter={() => {
            if (isBooked(date)) {
              setAnchorId('tooltip-soldout-' + date);
              if (!isOpen) {
                setIsOpen(true);
              }
            } else {
              setIsOpen(false);
            }
          }}
          id={isBooked(date) ? `tooltip-soldout-${date}` : ''}
          data-tooltip-html="<div/>"
        >
          <span>{getDate(date)}</span>
          <span>{price !== undefined ? '$' + price : ''}</span>
        </div>
        <Tooltip
          anchorId={anchorId}
          isOpen={isOpen}
          offset={-10}
          className="tooltipDisabledDate"
        >
          <div className="bg-white">
            <p className="text-xl">SOLD OUT</p>
            {/* <Button href="#" disabled>
              Sign up for journey alerts
            </Button> */}
          </div>
        </Tooltip>
      </>
    );
  };

  const renderCustomHeader = ({
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        border: 0,
      }}
    >
      <span
        onClick={() => {
          if (departingIndex > 0) {
            setDepartingIndex(departingIndex - 1);
            decreaseMonth();
          }
        }}
        disabled={prevMonthButtonDisabled}
        className={styles.TrainDatePicker__arrowLeft}
      ></span>
      <Input
        type="select"
        className={styles.TrainDatePicker__select}
        onChange={({ target: { value } }) => {
          changeYear(+value.split(' ')[1]);
          changeMonth(months.indexOf(value.split(' ')[0]));
          setDepartingIndex(departingMonthYears.indexOf(value));
        }}
        value={departingMonthYears[departingIndex]}
      >
        {departingMonthYears.map((option) => (
          <option
            key={option}
            value={option}
            disabled={option.endsWith(' - unavailable')}
          >
            {option}
          </option>
        ))}
      </Input>
      <span
        onClick={() => {
          if (departingIndex < departingMonthYears.length - 1) {
            setDepartingIndex(departingIndex + 1);
            increaseMonth();
          }
        }}
        disabled={nextMonthButtonDisabled}
        className={styles.TrainDatePicker__arrowRight}
      ></span>
    </div>
  );

  const isAvailable = (day) => {
    let dayInMonth = day.getDate();
    let month = day.getMonth() + 1;
    const year = day.getFullYear();
    if (month < 10) {
      month = '0' + month;
    }
    if (dayInMonth < 10) {
      dayInMonth = '0' + dayInMonth;
    } else {
      dayInMonth = '' + dayInMonth;
    }
    const yearMonthInString = year + '-' + month;
    if (inline) {
      return (
        Object.keys(datesPrices).length &&
        Object.keys(datesPrices).includes(yearMonthInString) &&
        Object.keys(datesPrices[yearMonthInString]).includes(dayInMonth)
      );
    } else {
      return (
        Object.keys(journeyOpenDates).length &&
        Object.keys(journeyOpenDates).includes(yearMonthInString) &&
        Object.keys(journeyOpenDates[yearMonthInString]).includes(dayInMonth)
      );
    }
  };

  const isBooked = (day) => {
    let dayInMonth = day.getDate();
    let month = day.getMonth() + 1;
    const year = day.getFullYear();
    if (month < 10) {
      month = '0' + month;
    }
    if (dayInMonth < 10) {
      dayInMonth = '0' + dayInMonth;
    } else {
      dayInMonth = '' + dayInMonth;
    }
    const yearMonthInString = year + '-' + month;
    if (inline) {
      return false;
    } else {
      return (
        Object.keys(journeyOpenDates).length &&
        journeyOpenDates[yearMonthInString] &&
        !journeyOpenDates[yearMonthInString][dayInMonth]
      );
    }
  };

  const getTrainDeparturesAvailability = useCallback(
    async (productCode, arrivalStation, departureStation) => {
      try {
        let date = 'Any';
        const res = await trackPromise(
          axios.get(
            `/train-departures-availability?productCode=${productCode}&date=${date}&departureStation=${departureStation}&arrivalStation=${arrivalStation}`
          ),
          'gettingAvailableDates'
        );
        if (res.data.length) {
          for (const dateAvail of res.data) {
            const yearMonthDay = dateAvail.departureDate.split('-');
            if (!journeyOpenDates[yearMonthDay[0] + '-' + yearMonthDay[1]]) {
              journeyOpenDates[yearMonthDay[0] + '-' + yearMonthDay[1]] = {};
            }
            const yearMonthMap =
              journeyOpenDates[yearMonthDay[0] + '-' + yearMonthDay[1]];
            yearMonthMap[yearMonthDay[2]] =
              yearMonthMap[yearMonthDay[2]] || dateAvail.available;
          }
        }
        for (let i = 0; i < departingMonthYears.length; i++) {
          const monthYear = departingMonthYears[i].split(' ');
          let month = months.indexOf(monthYear[0]) + 1;
          if (month < 10) {
            month = '0' + month;
          }
          const monthYearKey = monthYear[1] + '-' + month;
          if (!Object.keys(journeyOpenDates).includes(monthYearKey)) {
            departingMonthYears[i] =
              monthYear[0] + ' ' + monthYear[1] + ' - unavailable';
          }
        }
      } catch (e) {
        showMessage('Something went wrong! Please try it later.', 'danger');
      }
    },
    [journeyOpenDates, departingMonthYears, showMessage, axios]
  );

  useEffect(() => {
    if (!inline && departingMonthYears.length) {
      getTrainDeparturesAvailability(
        productCode,
        arrivalStation,
        departureStation
      );
    }
  }, [
    inline,
    departingMonthYears,
    productCode,
    departureStation,
    arrivalStation,
    getTrainDeparturesAvailability,
  ]);

  const getTrainLowestPrices = useCallback(
    async (cabins, productCode, packageId) => {
      try {
        let cabinsString = '';
        cabins.forEach((c) => {
          cabinsString += '&passengersPerElement=' + c.value;
        });
        const res = await trackPromise(
          axios.get(
            `/train-departures-lowest-price?productCode=${productCode}&packageId=${packageId}&date=Any${cabinsString}`
          ),
          'gettingAvailableDates'
        );
        if (res.data.length) {
          for (const dateLowest of res.data) {
            const yearMonthDay = dateLowest.departureDate.split('-');
            if (!datesPrices[yearMonthDay[0] + '-' + yearMonthDay[1]]) {
              datesPrices[yearMonthDay[0] + '-' + yearMonthDay[1]] = {};
            }
            datesPrices[yearMonthDay[0] + '-' + yearMonthDay[1]][
              yearMonthDay[2]
            ] = dateLowest.lowestPricePerPerson;
          }
        }
        for (let i = 0; i < departingMonthYears.length; i++) {
          const monthYear = departingMonthYears[i].split(' ');
          let month = months.indexOf(monthYear[0]) + 1;
          if (month < 10) {
            month = '0' + month;
          }
          const monthYearKey = monthYear[1] + '-' + month;
          if (!Object.keys(datesPrices).includes(monthYearKey)) {
            departingMonthYears[i] =
              monthYear[0] + ' ' + monthYear[1] + ' - unavailable';
          }
        }
      } catch (e) {
        showMessage('Something went wrong! Please try it later.', 'danger');
      }
    },
    [datesPrices, departingMonthYears, showMessage, axios]
  );

  useEffect(() => {
    if (inline && departingMonthYears.length) {
      getTrainLowestPrices(cabins, productCode, packageId);
    }
  }, [
    inline,
    cabins,
    productCode,
    packageId,
    departingMonthYears,
    getTrainLowestPrices,
  ]);

  useEffect(() => {
    const newDepartingMonthYears = [];
    const newYearsMonth = [];
    let currentDate = new Date();
    for (let i = 0; i < 25; i++) {
      let month = months[getMonth(currentDate)];
      let year = getYear(currentDate);
      newDepartingMonthYears.push(month + ' ' + year);
      if (newYearsMonth[year] === undefined) {
        newYearsMonth[year] = ['Any month', month];
      } else {
        newYearsMonth[year].push(month);
      }
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    setDepartingMonthYears(newDepartingMonthYears);
    setYearsMonth(newYearsMonth);
  }, []);

  useEffect(() => {
    if (dateForTrains !== 'Any' && !dateForTrains?.endsWith('-0')) {
      const selectDate = new Date(dateForTrains);
      setSelectedDate(selectDate);
      if (departingMonthYears.length) {
        let month = months[getMonth(selectDate)];
        let year = getYear(selectDate);
        setDepartingIndex(departingMonthYears.indexOf(month + ' ' + year));
      }
    }
  }, [departingMonthYears, dateForTrains, setSelectedDate]);

  return (
    <div className={styles.TrainDatePicker__container}>
      {!inline && (
        <div className="d-flex">
          <div
            className={styles.TrainDatePicker__navText}
            onClick={() => {
              // dispatch(setDateForTrains('Any'));
              close();
            }}
          >
            Any date
          </div>
          <div
            className={styles.TrainDatePicker__navText}
            onClick={() => setPickSpecificDate(true)}
          >
            Specific date
          </div>
          <div
            className={styles.TrainDatePicker__navText}
            onClick={() => setPickSpecificDate(false)}
          >
            Specific month
          </div>
          <Col className="d-flex justify-content-end">
            <span
              onClick={() => close()}
              className={styles.TrainDatePicker__closeButton}
            ></span>
          </Col>
        </div>
      )}
      <Row>
        {pickSpecificDate ? (
          <>
            <div className="d-flex justify-content-center">
              {gettingAvailableDates ? (
                <LoadingSpinner />
              ) : (
                <DatePicker
                  renderDayContents={renderDayContents}
                  renderCustomHeader={renderCustomHeader}
                  onChange={(date) => {
                    if (isBooked(date)) return;
                    const timeZoneOffset = date.getTimezoneOffset() * 60000;
                    date = new Date(date - timeZoneOffset)
                      .toISOString()
                      .slice(0, 10);
                    if (!inline) {
                      // dispatch(setDateForTrains(date));
                      close();
                    } else {
                      setSelectedDate(new Date(date));
                    }
                  }}
                  selected={selectedDate}
                  shouldCloseOnSelect={false}
                  inline
                  formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
                  dayClassName={(date) =>
                    isBooked(date) ? 'notAvailable' : 'available'
                  }
                  filterDate={isAvailable}
                />
              )}
            </div>
            <Message />
          </>
        ) : (
          <div
            className="d-flex flex-column align-items-center"
            style={{ minWidth: '400px' }}
          >
            <Row>
              <Col>
                <span
                  className={styles.TrainDatePicker__arrowLeft}
                  onClick={() => {
                    if (yearsMonth[startYear - 1] !== undefined) {
                      setStartYear(startYear - 1);
                    }
                  }}
                ></span>
              </Col>
              <Col>
                <div className={styles.TrainDatePicker__year}>{startYear}</div>
              </Col>
              <Col>
                <span
                  className={styles.TrainDatePicker__arrowRight}
                  onClick={() => {
                    if (yearsMonth[startYear + 1] !== undefined) {
                      setStartYear(startYear + 1);
                    }
                  }}
                ></span>
              </Col>
            </Row>
            <Row>
              <ul className={styles.TrainDatePicker__grid}>
                {yearsMonth[startYear].map((month, i) => (
                  <li
                    key={i}
                    className="flex align-items-center align-items-center pointer-events"
                    onClick={() => {
                      // let date = '';
                      // if (month === 'Any month') {
                      //   date = 'Any';
                      // } else {
                      //   date =
                      //     startYear + '-' + (months.indexOf(month) + 1) + '-0';
                      // }
                      // dispatch(setDateForTrains(date));
                      close();
                    }}
                  >
                    <span
                      style={{ fontSize: '2rem', margin: '0 0.5rem 0.5rem 0' }}
                    >
                      &#9633;
                    </span>
                    <span className={styles.TrainDatePicker__navText}>
                      {month}
                    </span>
                  </li>
                ))}
              </ul>
            </Row>
          </div>
        )}
      </Row>
    </div>
  );
};
TrainDatePicker.propTypes = {
  close: PropTypes.func,
  inline: PropTypes.bool,
  cabins: PropTypes.string,
  productCode: PropTypes.string,
  packageId: PropTypes.string,
  departureStation: PropTypes.string,
  arrivalStation: PropTypes.string,
};
export default TrainDatePicker;
