import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import CheckTime from '../../../../components/CheckTime/CheckTime';
import Policies from '../../../../components/Policies/Policies';
import formatRoomName from '../../../../helpers/formatRoomName/formatRoomName';
import useRateContent from '../../../../hooks/useRateContent/useRateContent';

const RoomPolicies = ({ booking, showCommission }) => {
  const { rateContent, loading } = useRateContent(
    booking.roomRate.code,
    booking.productCode,
    booking,
    true
  );

  const hotel = useSelector(
    (state) => state.belmond.hotels[booking.productCode]
  );

  if (loading)
    return <Skeleton height={100} containerTestId="loading-skeleton" />;
  return (
    <>
      <hr className="bbe-hr my-4" />
      <h3>
        {formatRoomName(booking.roomType.category.name, booking.roomType.title)}
      </h3>
      <CheckTime
        checkInTime={hotel.checkInTime}
        checkOutTime={hotel.checkOutTime}
      />
      <div className="mt-3">
        <Policies
          policies={rateContent.policyList?.[booking.roomType.code]}
          loading={loading}
          showCommission={showCommission}
        />
      </div>
    </>
  );
};

RoomPolicies.propTypes = {
  booking: PropTypes.object.isRequired,
  showCommission: PropTypes.bool,
};

export default RoomPolicies;
