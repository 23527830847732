import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/Cross.svg';
import { ReactComponent as PresentIcon } from '../../assets/images/icons/Present.svg';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import useTranslate from '../../hooks/useTranslate/useTranslate';

function BookingsCartItemAddonsItem({
  addon,
  productCode,
  existing,
  removed,
  onRemove,
  currencyCode,
}) {
  const { t } = useTranslate();
  const { printPrice } = usePrintPrice(productCode);
  const hotels = useSelector((state) => state.belmond.hotels);
  const hotel = hotels[productCode];

  const printPricePayload = {
    value: Number(addon.price),
  };

  // if a different currency code
  if (hotel.currency !== currencyCode) {
    if (!existing) {
      // if adding
      printPricePayload.toCurrency = currencyCode;
    } else {
      // if removing
      printPricePayload.baseCurrency = currencyCode;
    }
  }

  const { price: addonPrice } = printPrice(printPricePayload);

  return (
    <div className="d-flex flex-column my-4 w-100" key={addon.title}>
      <hr className="bbe-hr mb-4" />
      <div className="d-flex align-items-center">
        <PresentIcon style={{ marginRight: 15 }} />
        <div className="d-flex flex-row justify-content-between align-items-start w-100">
          <div style={removed ? { textDecoration: 'line-through' } : null}>
            <span style={{ fontWeight: 600 }}>{t('Extra')}</span> {addon.title}
          </div>

          {!removed && onRemove && (
            <Button
              className="textButton"
              onClick={onRemove}
              aria-label={`Remove ${addon.title}`}
            >
              <CrossIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div style={{ marginLeft: 35 }}>{t('Price')}</div>
        <div style={{ fontWeight: 600 }}>{addonPrice}</div>
      </div>
    </div>
  );
}

BookingsCartItemAddonsItem.propTypes = {
  addon: PropTypes.object,
  productCode: PropTypes.string,
  onRemove: PropTypes.func,
  removed: PropTypes.bool,
  currencyCode: PropTypes.string.isRequired,
  existing: PropTypes.bool,
};

export default BookingsCartItemAddonsItem;
