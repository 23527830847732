import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Currency from '../../../../components/Currency/Currency';
import GuestLogin from '../../../../components/GuestLogin/GuestLogin';
import LanguageSelector from '../../../../components/LanguageSelector/LanguageSelector';
import { CHINA_VERSION } from '../../../../globals';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './MobileMenuItems.module.css';

const MobileMenuItems = ({ productCode, hotelCurrency, onClickLogin }) => {
  const { t } = useTranslate();
  const userLoginEnabled = useSelector(
    (state) => state.appSettings.userLoginEnabled
  );

  return (
    <ul className={styles.Header__list}>
      <li>
        <a href="/findreservation" title="">
          {t('Find Reservation')}
        </a>
      </li>
      {productCode && (
        <li>
          <a href={`/select-room?productCode=${productCode}`}>
            {t('Select a room')}
          </a>
        </li>
      )}
      <li>
        <hr className="bbe-hr" />
      </li>

      {!CHINA_VERSION && userLoginEnabled && (
        <li className={styles.Header__guestLogin}>
          <button onClick={onClickLogin} className="w-100">
            <GuestLogin />
          </button>
        </li>
      )}
      <li className={styles.Header__currencies}>
        <Currency hotelCurrency={hotelCurrency} />
      </li>
      <li className={styles.Header__language}>
        <LanguageSelector productCode={productCode} />
      </li>
    </ul>
  );
};

MobileMenuItems.propTypes = {
  productCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hotelCurrency: PropTypes.string,
  onClickLogin: PropTypes.func,
};
export default MobileMenuItems;
