import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';
import styles from './Train.module.css';

const TrainSkeleton = () => {
  return (
    <div className={styles.Train__cardContainer}>
      <Row className="m-2">
        <Col xs="12" sm="12" xl="8">
          <Row>
            <Col xs="12" sm="4">
              <div className={styles.Train__imgContainer}>
                <Skeleton height={120} />
              </div>
            </Col>
            <Col xs="12" sm="8">
              <div className={styles.Train__pictureText}>
                <span className={styles.Train__name}>
                  <Skeleton />
                </span>

                <Skeleton height={100} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="12" sm="12" xl="4">
          <div className="pt-3">
            <span className={styles.Train__description}>
              <Skeleton width={250} />
            </span>
            <Row className="pt-3">
              <Col xs="12" sm="6" lg="12" className="pb-3">
                <Skeleton height={50} />
              </Col>
              <Col xs="12" sm="6" lg="12" className="pb-3">
                <Skeleton height={50} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="m-2">
        <div className={styles.Train__departures}>
          <div>
            <Skeleton />
          </div>
          <div>
            <Skeleton />
          </div>
        </div>
      </Row>
    </div>
  );
};

export default TrainSkeleton;
