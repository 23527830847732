import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/icons/CheckmarkWhite.svg';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import { bookingStatuses } from '../../redux/slices/bookingsSlice/bookingsSlice';
import LoadingButton from '../LoadingButton/LoadingButton';

const BookingButton = ({
  rateCode,
  rateName,
  roomTypeTitle,
  onBook,
  roomTypeCode,
  numRooms,
}) => {
  const { t } = useTranslate();
  const bookings = useSelector((state) => state.bookings.list);
  const currentBookingId = useSelector((state) => state.bookings.current);

  const editingConfirmedBooking = bookings.some(
    ({ status }) => status === bookingStatuses.CONFIRMED
  );

  const text =
    numRooms > 1
      ? t('Select')
      : editingConfirmedBooking
      ? t('Select Room')
      : t('Book Now');

  const currentBooking = bookings.find(
    (booking) => booking.id === currentBookingId
  );

  return (
    <LoadingButton
      type="button"
      className="button"
      ariaLabel={`Book ${rateName} for ${roomTypeTitle} now`}
      onClick={() => onBook('Regular')}
      loading={false}
      style={{ minWidth: 160 }}
    >
      {currentBooking?.roomType.code === roomTypeCode &&
      currentBooking?.roomRate.code === rateCode ? (
        <div className="d-flex text-white align-items-center">
          <CheckmarkIcon style={{ marginRight: 5 }} />
          {t('Keep Room')}
        </div>
      ) : (
        text
      )}
    </LoadingButton>
  );
};

BookingButton.propTypes = {
  rateCode: PropTypes.string,
  onBook: PropTypes.func.isRequired,
  roomTypeCode: PropTypes.string.isRequired,
  numRooms: PropTypes.number.isRequired,
  rateName: PropTypes.string,
  roomTypeTitle: PropTypes.string.isRequired,
};

export default BookingButton;
