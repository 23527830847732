import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/images/icons/ArrowUp.svg';
import formatRoomName from '../../../helpers/formatRoomName/formatRoomName';
import useRateContent from '../../../hooks/useRateContent/useRateContent';
import useTranslate from '../../../hooks/useTranslate/useTranslate';
import {
  bookingStatuses,
  bookingsCartStatuses,
} from '../../../redux/slices/bookingsSlice/bookingsSlice';
import BookingActionButtons from '../BookingActionButtons/BookingActionButtons';
import styles from '../BookingsCart.module.css';
import BookingsCartItemAddonsItem from '../BookingsCartItemAddonsItem';
import BookingsCartRoomRate from '../BookingsCartRoomRate/BookingsCartRoomRate';
import BookingsCartTime from '../BookingsCartTime/BookingsCartTime';
import Dates from '../Dates/Dates';
import People from '../People/People';
import TaxesAndFees from '../TaxesAndFees/TaxesAndFees';

const { UNSUBMITTED, CONFIRMED } = bookingStatuses;
const { EDIT_BOOKING, EDIT_ADDONS, ADD_NEW_BOOKING, CHECK_OUT } =
  bookingsCartStatuses;

const BookingsCartItem = ({
  bookingsCartStatus,
  booking,
  pendingAddonsActions,
  title,
  collapsable,
  defaultCollapsed,
  showVerticalSeparator,
  onRemove,
  onEdit,
  onRemoveAddon,
}) => {
  const { t } = useTranslate();
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const { rateContent, loading } = useRateContent(
    booking.roomRate.code,
    booking.productCode,
    booking,
    true
  );

  const taxes = rateContent.roomsPricing?.find(
    (roomPricing) => roomPricing.roomTypeCode === booking.roomType.code
  )?.taxes;

  const addons = useMemo(
    () =>
      bookingsCartStatus === EDIT_ADDONS
        ? [
            ...booking.addons.map((addon) => ({
              bookingId: booking.id,
              productCode: booking.productCode,
              addon,
              existing: true,
              toRemove: !!pendingAddonsActions.toRemove.find(
                (addonToRemove) =>
                  addonToRemove.productCode === booking.productCode &&
                  addonToRemove.bookingId === booking.id &&
                  addonToRemove.addon.code === addon.code
              ),
            })),
            ...pendingAddonsActions.toAdd
              .filter(
                (addon) =>
                  addon.productCode === booking.productCode &&
                  addon.bookingId === booking.id
              )
              .map(({ addon }) => ({
                bookingId: booking.id,
                productCode: booking.productCode,
                addon,
                toRemove: false,
              })),
          ]
        : null,
    [booking, pendingAddonsActions, bookingsCartStatus]
  );

  useEffect(() => {
    if (bookingsCartStatus === EDIT_ADDONS) {
      setCollapsed(!addons.length);
    }
  }, [addons?.length, bookingsCartStatus]);

  const showEditAction =
    bookingsCartStatus === CHECK_OUT && booking.status === UNSUBMITTED;

  const showActions =
    (bookingsCartStatus === EDIT_BOOKING ||
      bookingsCartStatus === ADD_NEW_BOOKING ||
      bookingsCartStatus === CHECK_OUT) &&
    booking.status === UNSUBMITTED;

  useEffect(() => {
    if (!collapsable && collapsed) {
      setCollapsed(false);
    }
  }, [collapsable, collapsed]);

  return (
    <div
      key={booking.id}
      className={`${
        showVerticalSeparator
          ? styles.BookingsCart__nextRoomContainer
          : styles.BookingsCart__roomSeparator
      } ${collapsed ? styles.BookingsCart__roomCollapsed : ''}`}
      data-testid={`roomContainer${booking.id}`}
    >
      <button
        type="button"
        aria-label={title}
        className={styles.BookingsCart__componentSubheading}
        onClick={() => collapsable && setCollapsed((prev) => !prev)}
      >
        <div
          className="d-flex align-items-baseline justify-content-between w-100"
          style={{ paddingBottom: collapsed && '12px' }}
        >
          <div style={{ textAlign: 'start' }}>
            {formatRoomName(booking.roomType.category.name)} <br />
            {booking.roomType.title}
          </div>
          {collapsable && (
            <div className={styles.BookingsCart__roomNumerator}>{title}</div>
          )}
        </div>
        {collapsable && (
          <div>{collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}</div>
        )}
      </button>

      {!collapsed && (
        <>
          <Dates startDate={booking.startDate} endDate={booking.endDate} />
          {bookingsCartStatus === EDIT_ADDONS && (
            <div className={styles.BookingsCart__roomDetails}>
              <BookingsCartRoomRate
                rateContent={rateContent}
                rateName={booking.roomRate.name}
                ratePrice={booking.roomRate.price}
                ratePriceBeforeTax={booking.roomRate.totalAmountBeforeTax}
                pricesPerNight={booking.roomRate.pricesPerNight}
                productCode={booking.productCode}
                rateCurrencyCode={booking.roomRate.currencyCode}
                agentCommission={
                  booking.agentId && booking.agentCrmId
                    ? rateContent.commissionablePercentage
                    : null
                }
              />
            </div>
          )}
          {bookingsCartStatus !== EDIT_ADDONS && (
            <>
              <hr className="bbe-hr" />
              <BookingsCartTime productCode={booking.productCode} />
              <hr className="bbe-hr" />
              <People
                numAdults={booking.numAdults}
                numChildren={booking.children.length}
              />
              <hr className="bbe-hr" />
              <div className={styles.BookingsCart__bookingConfirmationNumber}>
                {booking.status !== UNSUBMITTED &&
                  `${t('Booking')} # ${booking.id}-${booking.productCode}`}
              </div>
              <div className={styles.BookingsCart__roomDetails}>
                <BookingsCartRoomRate
                  rateContent={rateContent}
                  rateName={booking.roomRate.name}
                  ratePrice={booking.roomRate.price}
                  rateCurrencyCode={booking.roomRate.currencyCode}
                  pricesPerNight={booking.roomRate.pricesPerNight}
                  ratePriceBeforeTax={booking.roomRate.totalAmountBeforeTax}
                  productCode={booking.productCode}
                  agentCommission={
                    booking.agentId && booking.agentCrmId
                      ? rateContent.commissionablePercentage
                      : null
                  }
                />
              </div>
            </>
          )}
          {bookingsCartStatus === EDIT_ADDONS && (
            <div>
              {addons.map(({ addon, toRemove, ...rest }) => (
                <BookingsCartItemAddonsItem
                  {...rest}
                  key={`addon-${booking.id}-${booking.productCode}-${addon.code}`}
                  productCode={booking.productCode}
                  currencyCode={booking.roomRate.currencyCode}
                  addon={addon}
                  removed={toRemove}
                  onRemove={() =>
                    onRemoveAddon(booking.productCode, booking.id, addon)
                  }
                />
              ))}
            </div>
          )}
          {booking.status === CONFIRMED &&
            bookingsCartStatus !== EDIT_ADDONS && (
              <div>
                {booking.addons.map((addon) => (
                  <BookingsCartItemAddonsItem
                    productCode={booking.productCode}
                    key={`addon-${booking.id}-${booking.productCode}-${addon.code}`}
                    addon={addon}
                  />
                ))}
              </div>
            )}

          {loading && (
            <Skeleton
              height={30}
              className="my-4"
              containerTestId="rate-skeleton"
            />
          )}

          {!loading && taxes?.total ? (
            <>
              <hr className="bbe-hr" />
              <TaxesAndFees
                taxes={taxes}
                productCode={booking.productCode}
                currencyCode={booking.roomRate.currencyCode}
              />
            </>
          ) : null}

          {showActions && (
            <BookingActionButtons
              showEdit={showEditAction}
              onEdit={onEdit}
              onRemove={onRemove}
              roomTitle={booking.roomType.title}
              bookingsCartStatus={bookingsCartStatus}
            />
          )}
        </>
      )}
    </div>
  );
};

BookingsCartItem.propTypes = {
  booking: PropTypes.object,
  pendingAddonsActions: PropTypes.object,
  bookingsCartStatus: PropTypes.oneOf(Object.values(bookingsCartStatuses)),
  title: PropTypes.string,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  defaultCollapsed: PropTypes.bool,
  showVerticalSeparator: PropTypes.bool,
  collapsable: PropTypes.bool,
  onRemoveAddon: PropTypes.func,
};

export default BookingsCartItem;
