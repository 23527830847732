import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { forwardRef, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import RoomImage from '../../../../components/RoomImage/RoomImage';
import RoomMediaModal from '../../../../components/RoomMediaModal/RoomMediaModal';
import { gtmBookingInteraction } from '../../../../gtm/events';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './RoomType.module.css';
import RoomTypeModal from './components/RoomTypeModal/RoomTypeModal';
import RoomTypeSelector from './components/RoomTypeSelector/RoomTypeSelector';
import { splitDescriptionIntoLists } from './helpers';

const RoomType = forwardRef(
  (
    {
      code,
      title,
      categoryName,
      roomTypes,
      sleeps,
      video,
      images,
      bedding,
      size,
      shortDescription,
      longDescription,
      onChangeSelectedRoomType,
      specialCodeActive,
    },
    ref
  ) => {
    const { t } = useTranslate();
    const [roomTypeModalOpen, setRoomTypeModalOpen] = useState(false);
    const [roomMediaModalOpen, setRoomMediaModalOpen] = useState(false);
    const [roomMediaDefaultView, setRoomMediaDefaultView] = useState('images');
    const roomMediaModalOpenFromRoomTypeModal = useRef(false);

    const handleRoomImageImagesClick = () => {
      setRoomMediaDefaultView('images');
      setRoomMediaModalOpen(true);
    };

    const handleRoomImageVideoClick = () => {
      setRoomMediaDefaultView('video');
      setRoomMediaModalOpen(true);
    };

    const handleRoomMediaModalClose = () => {
      setRoomMediaModalOpen(false);
    };

    const handleRoomTypeModalRoomMediaModalOpen = () => {
      roomMediaModalOpenFromRoomTypeModal.current = true;
      setRoomTypeModalOpen(false);
    };

    const handleRoomMediaModalClosed = () => {
      if (roomMediaModalOpenFromRoomTypeModal.current) {
        setRoomTypeModalOpen(true);
        roomMediaModalOpenFromRoomTypeModal.current = false;
      }
    };

    const handleRoomTypeModalClose = () => {
      setRoomTypeModalOpen(false);
    };

    const handleRoomTypeModalClosed = () => {
      if (roomMediaModalOpenFromRoomTypeModal.current) {
        setRoomMediaModalOpen(true);
      }
    };

    const handleRoomDetails = () => {
      gtmBookingInteraction('Room Details');
      setRoomTypeModalOpen((prev) => !prev);
    };

    const { firstList, secondList } =
      splitDescriptionIntoLists(longDescription);

    return (
      <>
        <RoomMediaModal
          open={roomMediaModalOpen}
          title={title}
          images={images}
          video={video}
          defaultView={roomMediaDefaultView}
          onClose={handleRoomMediaModalClose}
          onClosed={handleRoomMediaModalClosed}
        />
        <RoomTypeModal
          categoryName={categoryName}
          open={roomTypeModalOpen}
          title={title}
          sleeps={sleeps}
          bedding={bedding}
          size={size}
          images={images}
          video={video}
          shortDescription={shortDescription}
          longDescription={longDescription}
          onRoomMediaModalOpen={handleRoomTypeModalRoomMediaModalOpen}
          onClose={handleRoomTypeModalClose}
          onClosed={handleRoomTypeModalClosed}
        />
        <Row className="position-relative mt-4">
          <Col md="5">
            <div className={styles.Room__slider} ref={ref}>
              <RoomImage
                mainImage={images[0]}
                title={title}
                onImagesClick={handleRoomImageImagesClick}
                onVideoClick={handleRoomImageVideoClick}
                video={video}
              />
            </div>
          </Col>
          <Col md="7" className={styles.Room__room}>
            <div className={styles.Room_info}>
              <div className={styles.Room__title}>{categoryName}</div>
              {!specialCodeActive && (
                <>
                  <RoomTypeSelector
                    roomTypes={roomTypes}
                    selectedRoomTypeCode={code}
                    onChange={onChangeSelectedRoomType}
                  />
                  <div className={styles.Room__roomDetails}>
                    <span>
                      {t('Sleeps')} {sleeps}
                    </span>
                    <span>{bedding}</span>
                    <span>
                      {size?.value}
                      {size?.units}
                    </span>
                  </div>
                  {longDescription.includes('<ul>') ? (
                    <div className={styles.Room__listGridContainer}>
                      <ul className={styles.Room__listGridColumn}>
                        {parse(firstList.join(''))}
                      </ul>
                      <ul className={styles.Room__listGridColumn}>
                        {parse(secondList.join(''))}
                      </ul>
                    </div>
                  ) : (
                    parse(longDescription)
                  )}

                  <Button
                    className="hoverEffectButton mb-3"
                    onClick={handleRoomDetails}
                    aria-label={`Open room details modal for ${title}`}
                  >
                    {t('Room Details')}
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  }
);

RoomType.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  roomTypes: PropTypes.array.isRequired,
  sleeps: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  video: PropTypes.string,
  bedding: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  shortDescription: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  onChangeSelectedRoomType: PropTypes.func.isRequired,
  specialCodeActive: PropTypes.bool,
};

export default RoomType;
