import mergeAvailabilityMetadata from './merge-availability-metadata';

const determineAvailabilityMetadata = (month, monthData, numRooms, moment) => {
  const daysInMonth = moment(month).daysInMonth();
  const availableDates = monthData.map(({ date }) => date);
  const monthDayAvailability = Array.from({ length: daysInMonth }).map(
    (_, d) => {
      d = d + 1;
      const dayString = d.toString().length === 1 ? `0${d}` : d.toString();
      const previousDayString =
        (d + -1).toString().length === 1 ? `0${d + -1}` : (d + -1).toString();

      const { minimumPrice, availabilityForRooms = [] } =
        monthData.find(({ date }) => date === dayString) || {};

      const { availabilityForRooms: availabilityForRoomsPreviousDay = [] } =
        monthData.find(({ date }) => date === previousDayString) || {};

      const availableForAllRooms = numRooms === availabilityForRooms.length;
      const availableForAllRoomsPreviousDay =
        numRooms === availabilityForRoomsPreviousDay.length;

      // get the favoured values from the metadata, e.g the max MinStayThrough highest value
      const metadata = mergeAvailabilityMetadata(availabilityForRooms);

      // if stay through only, disable check in on that day
      const isCheckIn =
        !!availableDates?.includes(dayString) &&
        availableForAllRooms &&
        !metadata.NoArrive;

      const isCheckOut =
        !!availableDates?.includes(previousDayString) &&
        availableForAllRoomsPreviousDay;

      const dayData = {
        date: `${month}-${dayString}`,
        isCheckIn,
        isCheckOut,
        metadata,
      };

      if (minimumPrice && availableForAllRooms) {
        dayData.minimumPrice = minimumPrice;
      }

      return dayData;
    }
  );

  return monthDayAvailability;
};

export default determineAvailabilityMetadata;
