import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import formatRoomName from '../../../../helpers/formatRoomName/formatRoomName';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useMessage from '../../../../hooks/useMessage/useMessage';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './../BookingsConfirmationActionButtons/BookingActionButtonsModal.module.css';

const CancelConfirmationBookingsModal = ({
  open,
  bookings,
  onConfirm,
  onCancel,
  error,
  loading,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();

  const [Message, showMessage, closeMessage] = useMessage();
  const [selectedBooking, setSelectedBooking] = useState(
    bookings.length === 1 ? bookings[0] : null
  );

  const handleConfirmationModalConfirm = () => {
    if (selectedBooking) {
      closeMessage();
      onConfirm(selectedBooking);
    }
  };

  useEffect(() => {
    if (error) {
      showMessage(error);
    }
  }, [error, showMessage]);

  return (
    <ConfirmationModal
      title={t('Are you sure you want to cancel?')}
      cancelButton={t('Do Not Cancel')}
      confirmButton={t('Cancel Now')}
      loading={loading}
      content={
        <div>
          <Message />
          <div className="d-flex flex-column align-items-start">
            <FormGroup>
              {bookings?.map((booking, i) => (
                <div
                  className={styles.BookingActionButtonsModal__inputContainer}
                  key={booking.id}
                >
                  <Input
                    type="radio"
                    name="editRadio"
                    value={i}
                    className={styles.BookingActionButtonsModal__inputCheckbox}
                    onChange={() => setSelectedBooking(booking)}
                    aria-label={`Cancel ${booking.id}`}
                    defaultChecked={bookings.length === 1}
                    id={booking.id}
                  />
                  <Label
                    for={booking.id}
                    className={styles.BookingActionButtonsModal__modalText}
                  >
                    <span style={{ fontWeight: 600 }}>
                      {formatRoomName(
                        booking.roomType.category.name,
                        booking.roomType.title
                      )}
                    </span>{' '}
                    / {moment(booking.startDate).format('ddd, MMM D, YYYY')} / #
                    {booking.id}-{booking.productCode}
                  </Label>
                </div>
              ))}
            </FormGroup>
          </div>
        </div>
      }
      open={open}
      onConfirm={handleConfirmationModalConfirm}
      onCancel={onCancel}
    />
  );
};
CancelConfirmationBookingsModal.propTypes = {
  open: PropTypes.bool,
  bookings: PropTypes.array,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
};
export default CancelConfirmationBookingsModal;
