import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import { useScreenReady } from '../../context/use-screen-ready';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { SelectHotelLoader } from './components';

const loaders = {
  '/': <SelectHotelLoader />,
};

const GeneralViewWrapper = () => {
  const { isScreenReady } = useScreenReady();
  const belmondPhone = useSelector((state) => state.belmond.phone);
  const { pathname } = useLocation();

  return (
    <div data-testid="general-view">
      <Header phone={belmondPhone} />
      <div style={{ display: isScreenReady ? 'block' : 'none' }}>
        <Outlet />
      </div>
      {!isScreenReady ? loaders[pathname] || <ViewLoader /> : null}
      <Footer
        address="4B Victoria House, Bloomsbury Square, London WC1B 4DA"
        phone={belmondPhone}
        facebookLink="https://www.facebook.com/belmondtravel"
        instagramLink="https://www.instagram.com/belmond"
      />
    </div>
  );
};

export default GeneralViewWrapper;
