import mergeMonths from './merge-months';

const updateCalendarAvailability = (results, calendarAvailability, moment) => {
  let resultsToMerge = results;
  let updatedCalendarAvailability = calendarAvailability;

  if (!calendarAvailability.length) {
    updatedCalendarAvailability = results[0];
    resultsToMerge = results.slice(1);
  }

  const reducedCalendarAvailability = resultsToMerge.reduce((prev, curr) => {
    if (moment(prev[prev.length - 1].date).isBefore(curr[0].date)) {
      return mergeMonths(prev, curr);
    } else {
      return mergeMonths(curr, prev);
    }
  }, updatedCalendarAvailability);

  return reducedCalendarAvailability;
};

export default updateCalendarAvailability;
