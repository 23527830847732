import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import usePrintPrice from '../../hooks/usePrintPrice/usePrintPrice';
import TrainModal from '../TrainModal/TrainModal';
import styles from './Train.module.css';

const Train = ({ data }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { printPrice } = usePrintPrice(data.productCode);

  return (
    <>
      <TrainModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        moreInfoLink={data.packageDetailUrl}
        title={data.headline}
        productCode={data.productCode?.toUpperCase()}
        packageId={data.packageId}
      />
      <div className={styles.Train__cardContainer}>
        <Row className="m-2">
          <Col xs="12" sm="12" xl="8">
            <Row>
              <Col xs="12" sm="4">
                <div className={styles.Train__imgContainer}>
                  <img
                    src={data.image}
                    alt={data.headline}
                    style={{ width: '100%' }}
                  />
                </div>
              </Col>
              <Col xs="12" sm="8">
                <div className={styles.Train__pictureText}>
                  <span className={styles.Train__name}>{data.headline}</span>
                  <span className={styles.Train__nights}>
                    {data.numberOfNights} nights
                  </span>
                  <span className={styles.Train__description}>
                    {data.description}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="12" sm="12" xl="4">
            <div className="pt-3">
              <span className={styles.Train__description}>
                Cabins from {printPrice(data.pricePerPerson)} per passanger
              </span>
              <Row className="pt-3">
                <Col xs="12" sm="6" lg="12" className="pb-3">
                  <Button
                    onClick={() => window.open(data.packageDetailUrl, '_blank')}
                    className={`button ${styles.Train__hoverBlackButton}`}
                  >
                    FULL DETAILS
                  </Button>
                </Col>
                <Col xs="12" sm="6" lg="12" className="pb-3">
                  <Button
                    className={`button ${styles.Train__whiteButton}`}
                    onClick={() => setIsOpenModal(true)}
                  >
                    DATES & BOOKINGS
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className="m-2">
          <div className={styles.Train__departures}>
            <div>
              <span className={styles.Train__bold}>Available departures:</span>
              <span> {data.departures}</span>
            </div>
            <div>
              <span className={styles.Train__bold}>Available cabins: </span>
              <span>{data.cabinType}</span>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};
Train.propTypes = {
  data: PropTypes.object,
};
export default Train;
