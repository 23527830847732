const removeFromCart = (booking, hotel) => {
  const data = {
    event: 'removeFromCart',
    currencyCode: hotel.currency,
    products: [
      {
        brand: 'Belmond',
        category: booking.roomRate.name,
        checkinDate: booking.startDate,
        checkoutDate: booking.endDate,
        country: hotel.address.country,
        id: booking.roomType.title,
        name: hotel.name,
        price: booking.roomRate.price / booking.roomRate.pricesPerNight.length, // average price per night
        productType: 'Hotel',
        quantity: booking.roomRate.pricesPerNight.length,
        city: hotel.address.city,
      },
    ],
  };
  window.dataLayer.push(data);
};

export default removeFromCart;
