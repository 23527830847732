const addToCart = (bookingView, booking, hotel) => {
  const { roomRate, startDate, endDate, roomType } = booking;

  const data = {
    event: 'addToCart',
    currencyCode: hotel.currency,
    addToCartType: bookingView,
    products: [
      {
        brand: 'Belmond',
        category: roomRate.name,
        checkinDate: startDate,
        checkoutDate: endDate,
        country: hotel.address.countryName,
        id: roomType.title,
        name: hotel.name,
        price: roomRate.price / roomRate.pricesPerNight.length, // average price per night
        productType: 'Hotel',
        quantity: roomRate.pricesPerNight.length,
        city: hotel.address.city,
      },
    ],
  };

  window.dataLayer.push(data);
};

export default addToCart;
