import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import useTranslate from '../../../hooks/useTranslate/useTranslate';
import styles from '../BookingsCart.module.css';
import Dates from '../Dates/Dates';
import People from '../People/People';

const NextRoomPlaceholder = ({ selectRoomForm, highlightedRoomIndex }) => {
  const { t } = useTranslate();
  const { watch } = selectRoomForm;
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const guestsPerRoom = watch('guestsPerRoom');

  const { numAdults = 2, children = 0 } = useMemo(
    () => guestsPerRoom?.[highlightedRoomIndex] || {},
    [guestsPerRoom, highlightedRoomIndex]
  );

  return (
    <div className={styles.BookingsCart__nextRoomContainer}>
      <h4 className={styles.BookingsCart__componentSubheading}>
        {t('Next Room')}
      </h4>
      <div className={styles.BookingsCart__nextRoomDates}>
        <Dates startDate={startDate} endDate={endDate} />
        <People numAdults={numAdults} numChildren={children.length} />
      </div>
    </div>
  );
};

NextRoomPlaceholder.propTypes = {
  selectRoomForm: PropTypes.object.isRequired,
  highlightedRoomIndex: PropTypes.number.isRequired,
};

export default NextRoomPlaceholder;
