import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { Form, useSearchParams } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import BookingsCart from '../../components/BookingsCart/BookingsCart';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Train from '../../components/Train/Train';
import TrainSkeleton from '../../components/Train/TrainSkeleton';
import useAxios from '../../hooks/useAxios/useAxios';
import useMessage from '../../hooks/useMessage/useMessage';
import { useScreenDetector } from '../../hooks/useScreenDetector/useScreenDetector';
import HeaderPicker from '../SelectRoom/components/HeaderPicker/HeaderPicker';
import styles from './SelectTrain.module.css';
import TrainDatePicker from './components/TrainDatePicker/TrainDatePicker';

const SelectTrain = () => {
  const axios = useAxios();
  const { isLargeDesktop } = useScreenDetector();
  const [numOfPassangers] = useState([1, 2, 3, 4, 5, 6]);
  const [citiesFromMap, setCitiesFromMap] = useState({});
  const [citiesToMap, setCitiesToMap] = useState({});
  const [citiesFrom, setCitiesFrom] = useState(['Any']);
  const [citiesTo, setCitiesTo] = useState(['Any']);
  const [cabinType, setCabinType] = useState(['Any']);
  const [cabinTypeCodes, setCabinTypeCodes] = useState({});
  const [trains, setTrains] = useState([]);
  const sortingWays = [
    'Belmond recommended',
    'Price: Low to High',
    'Price: High to Low',
    'Trip duration: Shortest to Longest',
    'Trip duration: Longest to Shortest',
  ];
  const [sortForm, setSortForm] = useState('Belmond recommended');
  const [passangersForm, setPassangersForm] = useState(2);
  const [fromForm, setFromForm] = useState('Any');
  const [toForm, setToForm] = useState('Any');
  const [cabinTypeForm, setCabinTypeForm] = useState('Any');
  const dateForTrains = useSelector((state) => state.reservation.dateForTrains);
  const [searchParams] = useSearchParams();
  const productCode = searchParams.get('productCode');
  const [Message, showMessage] = useMessage();
  const { promiseInProgress: searchTrainsInProgress } = usePromiseTracker({
    area: 'searchTrains',
  });

  const bookings = useSelector((state) => state.bookings.list);

  let dateForDisplay = dateForTrains;
  let dateStringArray = [];
  let dateString = '';

  if (dateForDisplay?.endsWith('-0')) {
    dateForDisplay = dateForDisplay.replace(new RegExp('-0$'), '-1');
    dateStringArray = new Date(dateForDisplay)
      .toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
      .replace(',', '')
      .split(' ');
    dateString = dateStringArray[0] + ' ' + dateStringArray[2];
  } else if (dateForDisplay !== 'Any') {
    dateStringArray = new Date(dateForDisplay)
      .toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
      .replace(',', '')
      .split(' ');
    dateString =
      dateStringArray[1] + ' ' + dateStringArray[0] + ' ' + dateStringArray[2];
  } else {
    dateString = dateForDisplay;
  }

  const submit = useCallback(async () => {
    try {
      const dateForSend = dateForTrains
        .toLowerCase()
        .replace(new RegExp('-0$'), '');

      // const dateForSend = dateForTrains.replace(new RegExp('-0$'), '');

      const res = await trackPromise(
        axios.get(
          `/train-availability?productCode=${productCode}&date=${dateForSend}&passengers=${passangersForm}&departureStation=${fromForm}&arrivalStation=${toForm}&cabinType=${cabinTypeCodes[cabinTypeForm]}`
        ),
        'searchTrains'
      );
      if (!res.data.length) {
        showMessage('We did not found any trains for this search :(');
      }

      res.data.forEach((data, i) => {
        data.index = i;
      });

      setTrains(res.data);
      setSortForm('Belmond recommended');
    } catch (e) {
      showMessage('Something went wrong! Please try it later.', 'danger');
    }
  }, [
    productCode,
    dateForTrains,
    passangersForm,
    fromForm,
    toForm,
    cabinTypeForm,
    cabinTypeCodes,
    showMessage,
    axios,
  ]);

  const parseArrivalStations = (arrivalStations) => {
    const newCitiesToMap = {};
    const allCitiesTo = [];
    for (let city in arrivalStations) {
      newCitiesToMap[city] = arrivalStations[city].stations[1];
      newCitiesToMap[city].sort();
      newCitiesToMap[city].unshift('Any');
      allCitiesTo.push(city);
    }
    allCitiesTo.sort();
    allCitiesTo.unshift('Any');
    newCitiesToMap['Any'] = allCitiesTo;
    setCitiesToMap(newCitiesToMap);
    setCitiesTo(allCitiesTo);
  };

  const parseDepartureStations = (departureStations) => {
    const newCitiesFromMap = {};
    const allCitiesFrom = [];
    for (let city in departureStations) {
      newCitiesFromMap[city] = departureStations[city].stations[1];
      newCitiesFromMap[city].sort();
      newCitiesFromMap[city].unshift('Any');
      allCitiesFrom.push(city);
    }
    allCitiesFrom.sort();
    allCitiesFrom.unshift('Any');
    newCitiesFromMap['Any'] = allCitiesFrom;
    setCitiesFromMap(newCitiesFromMap);
    setCitiesFrom(allCitiesFrom);
  };

  const getFiltersFromServers = useCallback(
    async (productCode) => {
      try {
        const res = await trackPromise(
          axios.get(`/train-filter-values?productCode=${productCode}`),
          'filters'
        );

        if (res?.data?.arrivalStations) {
          parseArrivalStations(res.data.arrivalStations);
        }

        if (res?.data?.departureStations) {
          parseDepartureStations(res.data.departureStations);
        }

        if (res?.data?.cabinTypes) {
          const newCabinType = ['Any'];
          const cabinTypeCodes = { Any: 'Any' };

          res.data.cabinTypes.forEach((r) => {
            newCabinType.push(r.cabinName);
            cabinTypeCodes[r.cabinName] = r.cabinId;
          });

          setCabinType(newCabinType);
          setCabinTypeCodes(cabinTypeCodes);
        }
      } catch (e) {
        showMessage('Something went wrong! Please try it later.', 'danger');
      }
    },
    [showMessage, axios]
  );

  useEffect(() => {
    getFiltersFromServers(productCode);
  }, [productCode, getFiltersFromServers]);

  const sortTrains = useCallback(
    (wayToSort) => {
      let trainToSort = [...trains].sort((a, b) => a.index - b.index);

      switch (wayToSort) {
        case 'Belmond recommended':
          break;
        case 'Price: Low to High':
          trainToSort.sort((a, b) => a.pricePerPerson - b.pricePerPerson);
          break;
        case 'Price: High to Low':
          trainToSort.sort((a, b) => b.pricePerPerson - a.pricePerPerson);
          break;
        case 'Trip duration: Shortest to Longest':
          trainToSort.sort((a, b) => a.numberOfNights - b.numberOfNights);
          break;
        case 'Trip duration: Longest to Shortest':
          trainToSort.sort((a, b) => b.numberOfNights - a.numberOfNights);
          break;
        default:
          break;
      }

      setTrains(trainToSort);
    },
    [trains]
  );

  useEffect(() => {
    if (citiesFromMap[fromForm]) {
      setCitiesTo(citiesFromMap[fromForm]);
      setToForm('Any');
    }
  }, [citiesFromMap, fromForm]);

  useEffect(() => {
    if (citiesToMap[toForm] && fromForm === 'Any') {
      setCitiesFrom(citiesToMap[toForm]);
      setFromForm('Any');
    }
  }, [citiesToMap, fromForm, toForm]);

  return (
    <>
      <div className="container-xxl">
        <Row className="mx-2 d-flex flex-row-reverse">
          <Col lg="4">
            {isLargeDesktop && (
              <div className="d-block sticky-top py-4" style={{ zIndex: 1 }}>
                <BookingsCart status="STAND_BY" bookings={bookings} />
              </div>
            )}
          </Col>
          <Col lg="8 py-4">
            <Form>
              <FormGroup>
                <Row>
                  <Col sm="2">
                    <div className={styles.SelectTrain__selectText}>
                      Passengers
                    </div>
                    <Input
                      type="select"
                      className={styles.SelectTrain__select}
                      onChange={({ target: { value } }) => {
                        setPassangersForm(value);
                      }}
                      value={passangersForm}
                    >
                      {numOfPassangers.map((data, i) => (
                        <option key={i} value={data}>
                          {data}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="2">
                    <div className={styles.SelectTrain__selectText}>
                      Departing
                    </div>
                    <HeaderPicker
                      className={styles.SelectTrain__select}
                      picker={<div>{dateString}</div>}
                      content={(close) => {
                        return (
                          <div>
                            <TrainDatePicker
                              close={close}
                              productCode={productCode}
                              departureStation={fromForm}
                              arrivalStation={toForm}
                            />
                          </div>
                        );
                      }}
                    />
                  </Col>
                  <Col sm="2">
                    <div className={styles.SelectTrain__selectText}>From</div>
                    <Input
                      type="select"
                      className={styles.SelectTrain__select}
                      onChange={({ target: { value } }) => {
                        setFromForm(value);
                      }}
                      value={fromForm}
                    >
                      {citiesFrom.map((data, i) => (
                        <option key={i} value={data}>
                          {data}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="2">
                    <div className={styles.SelectTrain__selectText}>
                      Travelling to
                    </div>
                    <Input
                      type="select"
                      className={styles.SelectTrain__select}
                      onChange={({ target: { value } }) => {
                        setToForm(value);
                      }}
                      value={toForm}
                    >
                      {citiesTo.map((data, i) => (
                        <option key={i} value={data}>
                          {data}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="2">
                    <div className={styles.SelectTrain__selectText}>
                      Cabin type
                    </div>
                    <Input
                      type="select"
                      className={styles.SelectTrain__select}
                      onChange={({ target: { value } }) => {
                        setCabinTypeForm(value);
                      }}
                      value={cabinTypeForm}
                    >
                      {cabinType.map((data, i) => (
                        <option key={i} value={data}>
                          {data}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm="2">
                    <Button
                      className={`button ${styles.SelectTrain__button} mt-4`}
                      type="button"
                      onClick={submit}
                    >
                      <div className="text-white">
                        {searchTrainsInProgress ? <LoadingSpinner /> : 'Search'}
                      </div>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
            {trains.length > 0 && (
              <Row>
                <Col>
                  <div className={styles.SelectTrain__routesText}>
                    {trains.length} Routes available
                  </div>
                </Col>
                <Col></Col>
                <Col>
                  <Input
                    type="select"
                    className={styles.SelectTrain__select}
                    onChange={({ target: { value } }) => {
                      setSortForm(value);
                      sortTrains(value);
                    }}
                    value={sortForm}
                  >
                    {sortingWays.map((data, i) => (
                      <option key={i} value={data}>
                        {data}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            )}
            <Row>
              <Message />
              {searchTrainsInProgress
                ? [...Array(10)].map((i) => <TrainSkeleton key={i} />)
                : trains.map((trainData) => (
                    <Train data={trainData} key={trainData.index} />
                  ))}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SelectTrain;
