import { useMemo } from 'react';
import usePrintPrice from '../usePrintPrice/usePrintPrice';

const useTotalPrice = (bookings, pendingAddonsActions = {}) => {
  const { printPrice } = usePrintPrice();

  const { totalPrice, differentCurrencies } = useMemo(() => {
    if (!bookings.length) return { totalPrice: 0, differentCurrencies: false };

    const pricesIncludingAddons = bookings.map((booking) => {
      // this will already be in the currency we require, no need to convert
      const addonsPrice =
        booking.addons?.reduce((prev, curr) => prev + curr.price, 0) || 0;

      const numericToAddAddonPrices =
        pendingAddonsActions?.toAdd
          ?.filter(({ bookingId }) => booking.id === bookingId)
          ?.reduce((prev, curr) => prev + Number(curr.addon.price), 0) || 0;

      // calculate the total price of addons to remove for this booking
      const numericToRemoveAddonPrices =
        pendingAddonsActions?.toRemove
          ?.filter(({ bookingId }) => booking.id === bookingId)
          ?.reduce((prev, curr) => prev - Number(curr.addon.price), 0) || 0;

      // combine them here, ready to display
      return {
        numericPrice:
          booking.roomRate.price +
          addonsPrice +
          numericToAddAddonPrices +
          numericToRemoveAddonPrices,
        currencyCode: booking.roomRate.currencyCode,
      };
    });

    // map all the currencyCodes, and check if there is more than one
    let currencyCodes = pricesIncludingAddons.map(
      (price) => price.currencyCode
    );

    const differentCurrencies = new Set(currencyCodes).size > 1;

    // if its just one currency, total it all up
    if (!differentCurrencies) {
      let numericTotal = pricesIncludingAddons.reduce(
        (acc, { numericPrice }) => acc + numericPrice,
        0
      );

      // will not work for muilt-property
      const formattedTotal = printPrice({
        value: numericTotal,
        productCode: bookings[0].productCode,
        // pass a base currency here as we might have a different currency code from the hotel
        // if this is the case, print price will ignore the conversion and display this rate currency
        baseCurrency: bookings[0].roomRate.currencyCode,
      }).price;

      return {
        totalPrice: formattedTotal,
        differentCurrencies: false,
      };
    } else {
      // otherwise, return nothing and a flag to show a message
      return {
        totalPrice: 0,
        differentCurrencies: true,
      };
    }
  }, [
    bookings,
    pendingAddonsActions?.toAdd,
    pendingAddonsActions?.toRemove,
    printPrice,
  ]);

  return { totalPrice, differentCurrencies };
};

export default useTotalPrice;
