import PropTypes from 'prop-types';
import React from 'react';
import Picker from '../../../../components/Picker/Picker';
import styles from './HeaderPicker.module.css';

const HeaderPicker = ({ picker, content, onToggle, className, label }) => {
  return (
    <Picker
      picker={picker}
      content={content}
      onToggle={onToggle}
      className={className ? className : styles.HeaderPicker__beHeaderPicker}
      label={label}
    />
  );
};

HeaderPicker.propTypes = {
  content: PropTypes.func,
  onToggle: PropTypes.func,
  picker: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default HeaderPicker;
