import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import TrainDatePicker from '../../views/SelectTrain/components/TrainDatePicker/TrainDatePicker';
import styles from './TrainModal.module.css';
import './Symbols.css';

// import { useSelector } from 'react-redux';
const TrainModal = ({
  title,
  moreInfoLink,
  productCode,
  packageId,
  isOpen,
  onClose,
}) => {
  // const dateForTrains = useSelector(
  //   (state) => state.reservation.dateForTrains
  // );

  const [cabins, setCabins] = useState([
    { cabin: 0, value: 2 },
    { cabin: 1, value: 2 },
  ]);

  const handleRemove = (elem) => {
    setCabins(cabins.filter((item) => item.cabin !== elem));
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        contentClassName={styles.TrainModal__modalWrapper}
        scrollable
      >
        <ModalHeader toggle={onClose} style={{ border: 'none' }}></ModalHeader>
        <ModalBody className={styles.TrainModal__body}>
          <div className={styles.TrainModal__header}>
            <span className={styles.TrainModal__title}>{title}</span>
            <div className="d-flex flex-column align-items-center mt-4">
              <span className={styles.TrainModal__headingText}>
                Please select your departure date, cabins and the number of
                passengers.
              </span>
              <span className={styles.TrainModal__headingText}>
                If you would like more information, you can view the
                <a className={styles.TrainModal__link} href={moreInfoLink}>
                  full journey details.
                </a>
              </span>
            </div>
          </div>
          <div>
            <Row className="pb-4">
              <Col
                lg="6"
                className={` ${styles.TrainModule__divider} d-flex justify-content-center pb-5`}
              >
                <div className="px-5 d-flex flex-column">
                  <span className={styles.TrainModal__bodyHeading}>
                    SELECT NUMBER OF CABINS & PASSENGERS
                  </span>
                  <div className="d-flex py-3 justify-content-between align-items-center">
                    <span className={styles.TrainModal__headingText}>
                      Cabins
                    </span>
                    <div>
                      <button
                        aria-label="minusCircleCabins"
                        className="circle minus"
                        onClick={() => {
                          handleRemove(cabins.length - 1);
                        }}
                        disabled={cabins.length === 1}
                      ></button>
                      <span className={styles.TrainModal__number}>
                        {cabins.length}
                      </span>
                      <button
                        aria-label="plusCircleCabins"
                        className="circle plus"
                        onClick={() => {
                          setCabins((prev) => [
                            ...prev,
                            { cabin: cabins.length, value: 2 },
                          ]);
                        }}
                        disabled={cabins.length === 6}
                      ></button>
                    </div>
                  </div>
                  {cabins.map((item, i) => (
                    <div
                      className="d-flex py-3 justify-content-between align-items-center"
                      key={item.cabin}
                    >
                      <div className={styles.TrainModal__headingText}>
                        <span
                          className={styles.TrainModal__headingText}
                          style={{ fontWeight: '500' }}
                        >
                          Cabin {i + 1}
                        </span>
                        passangers
                      </div>
                      <div>
                        <button
                          aria-label="minusCirclePassangers"
                          className="circle minus"
                          onClick={() => {
                            cabins[i].value -= 1;
                            setCabins((prev) => [...prev]);
                          }}
                          disabled={item.value === 1}
                        ></button>
                        <span
                          className={styles.TrainModal__number}
                          data-testid="cabinPassangersCount"
                        >
                          {item.value}
                        </span>
                        <button
                          aria-label="plusCirclePassangers"
                          className="circle plus"
                          onClick={() => {
                            cabins[i].value += 1;
                            setCabins((prev) => [...prev]);
                          }}
                          disabled={item.value === 2}
                        ></button>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg="6" className="d-flex justify-content-center">
                <div className="px-5 d-flex flex-column align-items-center">
                  <span className={styles.TrainModal__bodyHeading}>
                    SELECT A DEPARTURE DATE
                  </span>
                  <div>
                    <TrainDatePicker
                      inline
                      cabins={cabins}
                      productCode={productCode}
                      packageId={packageId}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <span className={styles.TrainModal__headingText}>
                      * Please note - prices are a guide per passenger and
                      subject to change based on journey availability
                    </span>
                    <span
                      className={styles.TrainModal__bodyHeading}
                      style={{ padding: '1rem 0' }}
                    >
                      CAN'T FIND YOUR IDEAL DATE?
                    </span>
                    <span className={styles.TrainModal__headingText}>
                      Want to be the first to know about new dates &
                      availability?
                    </span>
                    <span
                      className={styles.TrainModal__headingText}
                      style={{ textDecoration: 'underline' }}
                    >
                      Register your interest
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
TrainModal.propTypes = {
  title: PropTypes.string,
  moreInfoLink: PropTypes.string,
  productCode: PropTypes.string,
  packageId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
export default TrainModal;
