import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/EditPen.svg';
import SelectRoomMobilePicker from '../../../../components/SelectRoomMobilePicker/SelectRoomMobilePicker';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';

const SelectRoomMobileBar = ({ productCode, onPickerSubmit }) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const { watch } = useFormContext();
  const [mobilePickerOpen, setMobilePickerOpen] = useState(false);

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const guestsPerRoom = watch('guestsPerRoom');

  const numAdults = useMemo(
    () => guestsPerRoom.reduce((acc, room) => acc + room.numAdults, 0),
    [guestsPerRoom]
  );

  const numChildren = useMemo(
    () => guestsPerRoom.reduce((acc, room) => acc + room.numChildren, 0),
    [guestsPerRoom]
  );

  return (
    <div>
      <hr className="bbe-hr" />
      <div className="d-flex flex-column m-3">
        {mobilePickerOpen && (
          <SelectRoomMobilePicker
            productCode={productCode}
            onClose={() => {
              setMobilePickerOpen(false);
            }}
            onSubmit={() => {
              onPickerSubmit();
            }}
          />
        )}
        <Row className="w-100 d-flex">
          <button
            aria-label="Edit picker"
            type="button"
            className="d-flex"
            style={{ textAlign: 'left' }}
            onClick={() => setMobilePickerOpen(true)}
          >
            <Col xs="6 d-flex flex-column">
              <div className="bold text-uppercase">{t('Rooms & Guests')}</div>
              {guestsPerRoom.length}{' '}
              {guestsPerRoom.length > 1 ? t('rooms') : t('room')}, {numAdults}{' '}
              {numAdults > 1 ? t('adults') : t('adult')}
              {numChildren ? (
                <div>
                  {numChildren} {numChildren > 1 ? t('children') : t('child')}
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col xs="5">
              <div className="d-flex flex-column">
                <div className="bold text-uppercase">{t('Dates')}</div>
                <div className="text-nowrap">
                  {moment(startDate).format('MMM D')} -{' '}
                  {moment(endDate).format('MMM D YYYY')}
                </div>
              </div>
            </Col>
            <Col
              xs="1"
              className="d-flex justify-content-end"
              style={{ marginLeft: 8 }}
            >
              <EditIcon />
            </Col>
          </button>
        </Row>
      </div>
      <hr className="bbe-hr" />
    </div>
  );
};

SelectRoomMobileBar.propTypes = {
  productCode: PropTypes.string,
  onPickerSubmit: PropTypes.func,
};

export default SelectRoomMobileBar;
