const formatRoomName = (roomCategoryName, roomTypeName) => {
  const formattedRoomCategoryName = roomCategoryName.toLowerCase().endsWith('s')
    ? roomCategoryName.slice(0, -1)
    : roomCategoryName;

  const formattedRoomTypeName = roomTypeName ? ` - ${roomTypeName}` : '';

  return `${formattedRoomCategoryName}${formattedRoomTypeName}`;
};

export default formatRoomName;
