import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CURRENCY_SYMBOLS } from '../../constants';

const usePrintPrice = (productCode = null) => {
  const { exchanges, current: selectedCurrency } = useSelector(
    (state) => state.appSettings.currencies
  );
  const hotels = useSelector((state) => state.belmond.hotels);
  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const renderPrice = useCallback(
    (
      priceToPrint,
      numOfDecimals,
      fromCurrencyMid,
      baseCurrency,
      toCurrency,
      formatOnly
    ) => {
      const symbol =
        CURRENCY_SYMBOLS[
          formatOnly ? baseCurrency : toCurrency || selectedCurrency
        ]?.symbol;

      const exchangeRate = formatOnly
        ? 1
        : exchanges[toCurrency || selectedCurrency] / fromCurrencyMid;

      return `${symbol}${new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: numOfDecimals,
        minimumFractionDigits: numOfDecimals,
      }).format(exchangeRate * Number(priceToPrint))}`;
    },
    [selectedCurrency, exchanges]
  );

  const renderPriceAsNumber = useCallback(
    (priceToPrint, fromCurrencyMid, toCurrency, formatOnly) => {
      const exchangeRate = formatOnly
        ? 1
        : exchanges[toCurrency || selectedCurrency] / fromCurrencyMid;
      return exchangeRate * Number(priceToPrint);
    },
    [selectedCurrency, exchanges]
  );

  const printPrice = useCallback(
    (value, rounded = false) => {
      const numOfDecimals = rounded ? 0 : 2;
      if (typeof value === 'object') {
        const hotel = hotels[value.productCode || productCode];
        const baseCurrency = value.baseCurrency || hotel.currency;
        let fromCurrencyMid = exchanges[baseCurrency];

        // we can add a flag if we have a different base currency and want to convert
        // currently, if the base currency is different to the hotel currency
        // then we dont allow conversion

        const formatOnly = !value.toCurrency && baseCurrency !== hotel.currency;

        if (value.valueExcludingTaxesAndFees) {
          const isExlusiveTaxesAndFees =
            hotel?.bbeExclusiveTax && ['MX', 'US'].includes(countryCode);

          const price = isExlusiveTaxesAndFees
            ? value.valueExcludingTaxesAndFees
            : value.value;

          return {
            price: renderPrice(
              price,
              numOfDecimals,
              fromCurrencyMid,
              baseCurrency,
              value.toCurrency,
              formatOnly
            ),
            numericPrice: renderPriceAsNumber(
              price,
              fromCurrencyMid,
              value.toCurrency,
              formatOnly
            ),
            numericPriceWithoutConversion: price,
            isExlusiveTaxesAndFees,
            currencyCode: value.toCurrency || baseCurrency || hotel.currency,
          };
        } else {
          return {
            numericPrice: renderPriceAsNumber(
              value.value,
              fromCurrencyMid,
              value.toCurrency,
              formatOnly
            ),
            currencyCode:
              value.toCurrency || value.baseCurrency || hotel.currency,
            price: renderPrice(
              value.value,
              numOfDecimals,
              fromCurrencyMid,
              baseCurrency,
              value.toCurrency,
              formatOnly
            ),
          };
        }
      }

      return renderPrice(
        value,
        numOfDecimals,
        exchanges[hotels[productCode].currency]
      );
    },
    [
      exchanges,
      hotels,
      countryCode,
      productCode,
      renderPrice,
      renderPriceAsNumber,
    ]
  );

  // This is no longer used, but the code might come in handy later on
  // so we will keep it here!

  const convertToHotelCurrency = useCallback(
    (value, fromCurrency) => {
      const hotel = hotels[productCode];
      const hotelCurrency = hotel.currency;

      // If the currencies are the same, no conversion is needed
      if (fromCurrency === hotelCurrency) {
        return value;
      }

      // Convert to USD first (as it's the base currency with rate 1000)
      const valueInUSD = (value * 1000) / exchanges[fromCurrency];

      // Then convert from USD to hotel currency
      const convertedPrice = (valueInUSD * exchanges[hotelCurrency]) / 1000;

      return convertedPrice;
    },
    [exchanges, hotels, productCode]
  );

  return {
    printPrice,
    convertToHotelCurrency,
  };
};

export default usePrintPrice;
