import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import DevLogin from './components/DevLogin/DevLogin';
import ViewLoader from './components/ViewLoader/ViewLoader';
import {
  currencies as hardcodedCurrencies,
  trains as hardcodedTrainsList,
} from './constants';
import { CHINA_VERSION, env } from './globals';
import ScriptLoader from './helpers/ScriptLoader/ScriptLoader';
import devLoginCheck from './helpers/devLoginCheck';
import getCountryCode from './helpers/get-country-code';
import useAxios from './hooks/useAxios/useAxios';
import useCheckReach5Session from './hooks/useCheckReach5Session/useCheckReach5Session';
import {
  fetchExchanges,
  fetchLanguages,
  fetchStaticCnTranslations,
  setCountryCode,
  setCurrencies,
  setExtendedVersion,
  setUserLoginEnabled,
} from './redux/slices/appSettingsSlice/appSettingsSlice';
import {
  fetchHotels,
  setPhone,
  setTrains,
} from './redux/slices/belmondSlice/belmondSlice';
import { fetchMedia } from './redux/slices/mediaSlice/mediaSlice';
import { checkActiveAuthSession } from './redux/slices/userSlice/userSlice';

const Root = () => {
  useCheckReach5Session();
  const axios = useAxios();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const extendedVersionParam = searchParams.get('extended_version');

  const isDevLoginValid =
    JSON.parse(localStorage.getItem('validUser'))?.valid || env === 'prod';

  const fetchCountryCode = useCallback(async () => {
    const result = await getCountryCode();
    dispatch(setCountryCode(result));
  }, [dispatch]);

  const fetchMetadata = useCallback(async () => {
    if (!countryCode) return;

    try {
      const res = await axios.get('/metadata', {
        params: { country_code: countryCode },
      });

      dispatch(setPhone(res.data.phone));
      dispatch(setUserLoginEnabled(res.data.userLoginEnabled));
    } catch (e) {
      console.error(e?.message || e);
    }
  }, [axios, dispatch, countryCode]);

  useEffect(() => {
    if (extendedVersionParam === '1') {
      dispatch(setExtendedVersion(true));
    } else if (extendedVersionParam === '0') {
      dispatch(setExtendedVersion(false));
    }
  }, [extendedVersionParam, dispatch]);

  useEffect(() => {
    const initCalls = async () => {
      try {
        await fetchCountryCode();
      } catch (e) {
        // ignore - falls back to GB inside getCountryCode
      } finally {
        const promises = [
          fetchMetadata(),
          dispatch(fetchExchanges(axios)),
          dispatch(fetchLanguages(axios)),
          dispatch(fetchHotels(axios)),
          dispatch(fetchMedia(axios)),
        ];

        if (CHINA_VERSION) {
          promises.push(dispatch(fetchStaticCnTranslations(axios)));
        }

        await Promise.all(promises);

        dispatch(setCurrencies(hardcodedCurrencies));
        dispatch(setTrains(hardcodedTrainsList));
        setLoading(false);
        devLoginCheck();
      }
    };

    if (scriptsLoaded) {
      initCalls();
    }
  }, [fetchMetadata, dispatch, scriptsLoaded, axios, fetchCountryCode]);

  useEffect(() => {
    dispatch(checkActiveAuthSession());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location]);

  return (
    <>
      {/* TODO: rethink loading here, it shows a white page until we load the outlet  */}
      {/* but we can't load the outlet until some calls are done */}
      {/* move the data fetches from here into the outlet(?) */}
      {loading && (
        <div style={{ paddingTop: 75 }}>
          <ViewLoader />
        </div>
      )}
      <ScriptLoader onScriptsLoad={() => setScriptsLoaded(true)} />
      {isDevLoginValid ? scriptsLoaded && !loading && <Outlet /> : <DevLogin />}
    </>
  );
};

export default Root;
