import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as GalleryIcon } from '../../assets/images/icons/Gallery.svg';
import { ReactComponent as PlayIcon } from '../../assets/images/icons/Play.svg';
import AccesibilityDiv from '../AccesibilityElement/AccesibilityElement';
import styles from './RoomImage.module.css';

const RoomImage = ({
  mainImage,
  title,
  onImagesClick,
  onVideoClick,
  video,
}) => {
  return (
    <>
      <div className={styles.RoomImage__roomImages}>
        <AccesibilityDiv
          tagName="div"
          onClick={onImagesClick}
          ariaLabel="mainPicture"
          className={styles.RoomImage__mainImage}
          role="button"
          style={{
            backgroundImage: `url("${mainImage}")`,
          }}
        ></AccesibilityDiv>
        <div className={styles.RoomImage__tools}>
          {video && (
            <AccesibilityDiv
              role="button"
              onClick={onVideoClick}
              ariaLabel={`Open video gallery for ${title}`}
              className={styles.RoomImage__videoPlayer}
              tagName="div"
              id={`ImageGallery-${title}ID`}
            >
              <PlayIcon />
            </AccesibilityDiv>
          )}
          <AccesibilityDiv
            onClick={onImagesClick}
            role="button"
            ariaLabel={`Open image gallery for ${title}`}
            tagName="div"
            id={`ImageGallery-${title}ID`}
          >
            <GalleryIcon />
          </AccesibilityDiv>
        </div>
      </div>
    </>
  );
};
RoomImage.propTypes = {
  title: PropTypes.string,
  onImagesClick: PropTypes.func,
  onVideoClick: PropTypes.func,
  video: PropTypes.string,
  mainImage: PropTypes.string,
};

export default RoomImage;
