import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './AllStaysIncludedBox.module.css';

const AllStaysIncludedBox = ({ productCode, icons }) => {
  const allStaysInclude = useSelector(
    (state) => state.belmond.hotels[productCode]?.allStaysInclude
  );
  const media = useSelector((state) => state.media.mediaUrls);

  const logo = media.find((mediaItem) =>
    mediaItem.includes(`logo_${productCode}`)
  );

  const icon = media.find((mediaItem) =>
    mediaItem.includes(`AllStaysIncludedIcons/${productCode}`)
  );

  return (
    <div className={styles.AllStaysIncludedBox__container}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img
          src={icon}
          style={{ width: '35%', margin: '10px 0' }}
          alt={`${productCode} all stays included icon`}
        />
        <img src={logo} style={{ width: '50%' }} alt={`${productCode} logo`} />
      </div>
      <div>
        <div className="p-2">
          {allStaysInclude.headers.map((header, index) => (
            <div
              key={index}
              className="d-flex flex-column justify-content-center align-items-center mt-4"
            >
              <div className={styles.AllStaysIncludedBox__sectionHeader}>
                {header}
              </div>
              <div className={styles.AllStaysIncludedBox__sectionDescription}>
                {allStaysInclude.sections[index]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AllStaysIncludedBox.propTypes = {
  productCode: PropTypes.string,
  icons: PropTypes.object,
};

export default AllStaysIncludedBox;
