import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/icons/Calendar.svg';
import AccesibilityElement from '../../../../components/AccesibilityElement/AccesibilityElement';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import AddToCalendarModal from '../AddToCalendarModal/AddToCalendarModal';

const AddConfirmationBookingsToCalendarButton = ({ bookings }) => {
  const { t } = useTranslate();
  const [addToCalendarModalOpen, setAddToCalendarModalOpen] = useState(false);

  return (
    <>
      <AddToCalendarModal
        open={addToCalendarModalOpen}
        bookings={bookings}
        onClose={() => setAddToCalendarModalOpen(false)}
      />

      <AccesibilityElement
        tagName="div"
        ariaLabel="Open Calendar Modal"
        className="d-flex align-items-center"
        style={{ cursor: 'pointer' }}
        onClick={() => setAddToCalendarModalOpen(true)}
      >
        <CalendarIcon style={{ marginRight: 5 }} />
        {t('Add to calendar')}
      </AccesibilityElement>
    </>
  );
};

AddConfirmationBookingsToCalendarButton.propTypes = {
  bookings: PropTypes.array,
};

export default AddConfirmationBookingsToCalendarButton;
